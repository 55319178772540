<template>
<div>

<b-row class="textbox" >
        <b-col class="col-12 col-sm-6 col-xl-4 offset-xl-2 mt-2" ref="mainbox">
          <h4>Listings</h4>
         </b-col>
</b-row>  

<b-container class="pagecont">

<b-row class="mt-3">
    <b-col class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">

      <b-row class="mb-3">
        <b-col class="col-12">
          <b-btn variant="outline-info" size="lg"  block @click.stop="newListing()"><b-icon-plus-circle style="margin-right:15px"/>Create new listing</b-btn> 
        </b-col>
      </b-row>

      <b-row v-for="listing in listings" :key="listing.value">
            <b-col class="col-12 listingbox" @click.stop="showListingPage(listing)">
                <b-row>
                  <b-col class="col-12 text-left mb-3">
                    <b-btn variant="outline-info" size="sm" style="margin-right:5px" @click.stop="showPreview(listing.value)"><b-icon-eye /></b-btn> 
                    <b-btn variant="outline-success" size="sm" style="margin-right:5px" @click.stop="editListing(listing.value)"><b-icon-pencil /></b-btn> 
                    <b-btn variant="outline-danger" size="sm"><b-icon-trash /></b-btn>
                  </b-col>    
                </b-row>

                <b-row>
                    <b-col class="col-12 col-sm-8">
                    <h4><span translate="no">{{ listing.text }}</span></h4>
                    <h5>{{ listing.text2 }}</h5>
                    <p>{{ listing.page_desc }}</p>
                    </b-col>
                    <b-col class="col-12 col-sm-4 text-center text-sm-right">
                        <img v-if="listing.page_logo !== ''" class="provider_logo" :src="`https://care4medev.co.uk/images/logos/${listing.page_logo}` ">
                    </b-col>
                </b-row>

                <b-row>
                  <b-col class="col-12">
                    <h5>Status : {{ listing.publishtext }} {{ listing.publishdate }}</h5>
                  </b-col>
                </b-row>
                
             </b-col>
    </b-row>        
</b-col>
</b-row>
  
<b-modal  ref="preview-listing" size="md" hide-header  hide-footer centered>
    <provider-popup  :providerid="previewid" />
</b-modal>  

</b-container>

</div>
</template>

<style scoped>
.headline{
  background-color:rgba(255,255,255,0.7);
  position: absolute;
  padding: 5px;
  min-width: 100px;
  border-radius: 10px;
}

 
</style>

<script>
import axios from 'axios'

  export default {
    components: {
     
    },
  data(){
    return{
            user:{},
            provider:{},
            listings:[],
            display:true,
            previewid:0,
           }
  },


  mounted(){
        this.user = this.$store.getters.user
        this.fetchListings()
  },
  
  methods:{

      editListing(id){
          this.$store.commit('setYPosition',window.scrollY)
          this.$router.push({ name: 'managelisting', params: {id:id} }).catch(()=>{}) 
      },

      newListing(){
         this.$router.push({ name: 'managelisting', params: {id:0} }).catch(()=>{}) 
      },

      showPreview(id){
          this.previewid = id
          this.$refs['preview-listing'].show()
      },
    
      fetchListings(){
                axios.get('/api/providerlistings',{ 
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        provider:this.$store.getters.user.provider
                    }
                })
				.then((response) => {
                    if(response == 999){
                        console.log(response.error)
                    } else {
                            this.listings = response.data.Pages
                            }  
                    });
           },

      showListingPage(event){
              this.$router.push({ name: 'pagelisting', params: {id:event.value} }).catch(()=>{}) 
          },     
           
   
  }            
}
</script>