<template>
<div>
    <v-row>
        <v-col class="col-12 col-sm-8 offset-sm-2">
         
        <v-text-field 
            label="Search"
            autocomplete="off" 
            v-model="searchtext2"
            @keyup="search"
            @input="clearSearch()"
            prepend-icon="mdi-magnify"
            />

        </v-col>    

    </v-row> 
</div> 
</template>

<script>
    export default {
        data(){
        return {
            searchtext2:'',
            };
        },

       
        methods: {

            search(key){
             
            if(key.key == 'Enter'){
                if(this.searchtext2.length > 3){
                    this.$store.commit('setstext',this.searchtext2)
                    }    
               }  
           },

            clearSearch(){
              if(this.searchtext2 == ''){
                    this.$store.commit('setstext','')
                }   
           
            },

        }
	
};

</script> 