<template>  
<div>

 <b-row class="textbox">
        
    <b-col class="col-12 col-md-12 col-xl-8 offset-xl-2 mt-2">
          <h4>Account Details</h4>
         </b-col>
   </b-row>  
   
<b-container class="pagecont">

   <b-row class="mt-5">
    <b-col class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3">


   <b-row class="mt-5">
        <b-col class="col-12 col-sm-4">Organisation</b-col>
        <b-col class="col-12 col-sm-8">{{ provider.text}}</b-col>
    </b-row>

    <b-row class="mt-3">    
     <b-col class="col-12 col-sm-4">Name</b-col>
     <b-col class="col-12 col-sm-8">{{ provider.user_contact }}</b-col>
    </b-row>
    
    <b-row class="mt-3">
        <b-col class="col-12 col-sm-4">Email</b-col>
        <b-col class="col-12 col-sm-8">{{ provider.email}}</b-col>
    </b-row>
    
    <b-row class="mt-3">
        <b-col class="col-12 col-sm-4">Phone</b-col>
        <b-col class="col-12 col-sm-8">{{ provider.phone}}</b-col>
    </b-row>
    
    <b-row class="mt-3">
        <b-col class="col-12 col-sm-4">Website</b-col>
        <b-col class="col-12 col-sm-8">{{ provider.website}}</b-col>
    </b-row>
   
    <b-row class="mt-3">
        <b-col class="col-12 col-sm-4">Address</b-col>
        <b-col class="col-12 col-sm-8">
                <b-row>
                    <b-col class="col-12">{{ provider.add1}}</b-col>
                    <b-col class="col-12">{{ provider.add2}}</b-col>
                    <b-col class="col-12">{{ provider.add3}}</b-col>
                    <b-col class="col-12">{{ provider.postcode}}</b-col>
                </b-row>    
        </b-col>
    </b-row>
    </b-col>
</b-row>        

</b-container>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data(){
    return{
            user:{},
            provider:{},
        
          }
  },


  created(){ 
        this.user = this.$store.getters.user
        this.fetchProvider()
},
  
  methods:{

      fetchProvider(){
                axios.get('/api/provider',{ 
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        id:this.$store.getters.user.provider,
                        //all:1
                    }
                })
				.then((response) => {
                    if(response == 999){
                        console.log(response.error)
                    } else {
                            this.provider = response.data.Provider[0]
                            this.display = true
                            }
                    });
           },

    
  }            
}
</script>