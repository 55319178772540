<template>
<div class="mainpage">
<b-container class="pagecont" style="margin-top:100px">
   <b-row class="textbox">
        
        <b-col class="col-12">
          <h4>Register</h4>
          <h6>To register your organisation with Care4me, please complete this form. On completion of your registration you will receive an email with your login details to create your FREE listing on the site.</h6>
        </b-col>
   </b-row>  

   <b-row class="mt-5">
    <b-col class="col-12 col-sm-6 col-lg-4 offset-lg-1">
        <b-row>
          <b-col class="col-12"><h5>Create Account</h5></b-col>
          
          <b-col class="col-12 mt-3">
                <label>First Name</label>
                <b-form-input 
                            type="search" 
                            placeholder="first name" 
                            autocomplete="off"
                            v-model="register.fname"
                            name="register.fname"
                            v-validate="{required:true}"
                            :class="{'input': true, 'is-danger': errors.has('register.fname') }"  
                            class="darkinput"
                            @input = "validateField('register.fname')"
                            />
                            <span v-if="errors.first('register.fname')" style="color:red;">**</span>
          </b-col>

          <b-col class="col-12 mt-3">
                      <label>Last Name</label>
              <b-form-input 
                          type="search"
                          placeholder="last name"
                          autocomplete="off" 
                          v-model="register.lname" 
                          name="register.lname"
                          v-validate="{required:true}"
                          :class="{'input': true, 'is-danger': errors.has('register.lname') }"
                          class="darkinput"
                          @input = "validateField('register.lname')"  
                          />
                          <span style="color:red;">{{ errors.first('register.lname') }}</span>
          </b-col>

    
        <b-col class="col-12 mt-3">
                <label>Email</label>
				<b-form-input 
                    type="search"
                    placeholder="login email"
                    autocomplete="off" 
                    v-model="register.email" 
                    name="register.email"
                    v-validate="{required:true}"
                    :class="{'input': true, 'is-danger': errors.has('register.email') }"
                    class="darkinput"
                    @input = "validateField('register.email')"
                    @change = "validateField('register.email')"
                    />
                    <span style="color:red;">{{ errors.first('register.email') }}</span>
		</b-col>

    
        <b-col class="col-12 mt-3" >
                <label>Password</label>
				<b-form-input 
                    type="search"
                    placeholder="new password"
                    autocomplete="off"
                    name="register.password" 
                    v-model="register.password" 
                    v-validate="{required:true}"
                    :class="{'input': true, 'is-danger': errors.has('register.password') }"
                    class="darkinput"
                    @input = "validateField('register.password')"
                    @change = "validateField('register.password')" 
                    />
        </b-col>

        <b-col class="col-12 mt-3" >
                <label>Confirm Password</label>
				<b-form-input 
                    type="search"
                    placeholder="new password"
                    autocomplete="off" 
                    v-model="passwordconfirm" 
                    class="darkinput"  
                    />
        </b-col>

        </b-row>

    </b-col>    


    <b-col class="col-12 col-sm-6 col-lg-4 offset-lg-1">
      <b-row>
        <b-col class="col-12"><h5>Your Organisation</h5></b-col>
        <b-col class="col-12 mt-3">
                <label>Organisation Name</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="register.company" 
                    name="register.company"
                    v-validate="{required:true}"
                    :class="{'darkinput': true, 'is-danger': errors.has('register.company') }" 
                    @input = "validateField('register.company')"
                    @change = "validateField('register.company')"
                    />
                    <span style="color:red;">{{ errors.first('register.company') }}</span>
              </b-col>

          <b-col class="col-12 mt-3">
                <label>Organisation Website *optional</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="register.website" 
                    name="register.website"
                    v-validate="{required:true}"
                    :class="{'darkinput': true, 'is-danger': errors.has('register.website') }" 
                    @input = "validateField('register.website')"
                    @change = "validateField('register.website')"
                    />
                    <span style="color:red;">{{ errors.first('register.website') }}</span>
          </b-col>

          <b-col class="col-12 mt-3">
                <label>Contact Email</label>
                <b-form-input 
                    type="search"
                    placeholder="contact email"
                    autocomplete="off" 
                    v-model="register.contactemail" 
                    name="register.contactemail"
                    v-validate="{required:true}"
                    :class="{'input': true, 'is-danger': errors.has('register.contactemail') }"
                    class="darkinput"
                    />
                  <span style="color:red;">{{ errors.first('register.contactemail') }}</span>
          </b-col>


          <b-col class="col-12 mt-3">
                <label>Building / House name *optional</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="register.building" 
                    name="register.building"
                    />
          </b-col>

           <b-col class="col-12 mt-3">
                <label>Number & Street</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="register.address1" 
                    name="register.address1"
                    v-validate="{required:true}"
                    :class="{'darkinput': true, 'is-danger': errors.has('register.address1') }" 
                    @input = "validateField('register.address1')"
                    @change = "validateField('register.address1')"
                    />
                    <span style="color:red;">{{ errors.first('register.address1') }}</span>
            </b-col>

           <b-col class="col-12 col-sm-6 mt-3">
                <label>Area</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="register.address2" 
                    name="register.address2"
                    v-validate="{required:true}"
                    :class="{'darkinput': true, 'is-danger': errors.has('register.address2') }" 
                    @input = "validateField('register.address2')"
                    @change = "validateField('register.address2')"
                    />
                    <span style="color:red;">{{ errors.first('register.address2') }}</span>
          </b-col>

           <b-col class="col-12 col-sm-6 mt-3">
                <label>Postcode</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="register.postcode" 
                    name="register.postcode"
                    v-validate="{required:true}"
                    :class="{'darkinput': true, 'is-danger': errors.has('register.postcode') }" 
                    @input = "validateField('register.postcode')"
                    @change = "validateField('register.postcode')"
                    />
                    <span style="color:red;">{{ errors.first('register.postcode') }}</span>
          </b-col>

      </b-row>
    </b-col>

   </b-row>

   <b-row class="mt-5 mb-5">
      <b-col class="col-12">
        <b-button variant="success" block size="lg" @click.stop="validateAll()">Register my organisation</b-button>
      </b-col>
   </b-row>      

</b-container>

</div>
</template>

<script>
import axios from 'axios'
import { Validator } from 'vee-validate'
export default {
  data(){
    return{
          register:{
            fname:'',
            lname:'',
            email:'',
            password:'',
            building:'',
            address1:'',
            address2:'',
            postcode:''
          },
          passwordconfirm:'',
          emailerror:false
      }
  },

  mounted() {
            Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   
        },    
  methods:{

      validateAll(){
            this.$validator.validateAll().then(result => {
                   if(result){
                       this.registerUser()
                       } else alert('error')
               })
      },

      validateField(field){
              this.$validator.validate(field)
            },

      registerUser(){
         axios({
              method: 'post',    
              headers: {"Authorization" : "Bearer " + this.token},
              url: '/api/register',
              data: {
                  register:this.register
                  }
              })
               .then((response)=> {

                if(response.data.code == 999){
                      this.errorresponse = response.data
                      this.saving = false
                  } else {
                
                      console.log(response.data)
                      
                      //   this.sendEmail(response.data)
                      
                  }
      
              });	
            },
    
  }            
}
</script>