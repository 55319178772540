<template>
<div>

<b-container class="mapbox"> 

  <b-row class="mt-3" v-if="mapshow">
        <b-col class="col-12 m-0 p-0 border"> 
          <GmapMap
              ref='gmap'
              :center="center"
              :zoom="zoom"
              map-type-id="terrain"
              :style="getWidth()"
              :options="getOptions()"
              >   
              
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="showInfo(m.label.id)"
              >

              <GmapInfoWindow v-if="mapsel == m.label.id">
                  <span class="popup" @click.stop="providerpopup(m.label.id)">{{ m.label.text }}</span>
              </GmapInfoWindow> 

              </GmapMarker>

              <GmapCircle
                  v-if="range > 0 && range < 20"
                  ref="circleRef" 
                  :center="{ lat: center.lat, lng: center.lng }"
                  :radius="radius"
                  :visible="true"
                  :options="{fillColor:'blue',fillOpacity:0.1}"
              />
             
            </GmapMap>

        </b-col>
       
</b-row>

</b-container>
</div>
</template>

<style>

.popup:hover{
    text-decoration: underline;
}

.marker-position2{
  background-color:rgba(255,255,255,0.7) !important;
  position: relative !important;
  top: 40px !important;
  left: 10px !important;
  padding: 5px;
  border-radius: 10px;
  font-size:1em !important;
  line-height:1em !important;
}
.mapbox{
    max-width:100%;
    background-color: #FFFFFF;
    margin-bottom:0px;
    border-radius: 5px;
    padding-top:0;
    /* margin-top:70px; */
    -webkit-box-shadow: 5px 5px 8px -2px rgba(0,0,0,0.63); 
    box-shadow: 5px 5px 8px -2px rgba(0,0,0,0.63);
    color:rgba(0,0,0,0.7)
}
</style>

<script>
  import { mapState } from 'vuex'
  
  export default {
     
      data(){
        return {
                providers:[],
                markers:[],
                center:{},
                providersel:0,
                mapshow:true,
                showlisting:false,
                mapsel:'',
                radius:0,
                range:0,
                zoom:12
                };
                
              },

    mounted(){
          this.providers = this.$store.getters.mappoints
          this.showMap()
    },
 
    computed: mapState(['mappoints']),

    watch: {
            mappoints: function(){
                this.providers = this.mappoints
                this.showMap()
            },
    },

		methods: {

      showInfo(id){
        this.mapsel = id
      },

      showMap(){

            let lat = this.$store.getters.area.lat
            let long = this.$store.getters.area.lng

          
            if(lat == null || long == null){
              lat = 51.45685241614129
              long = -0.19349592251214856
            } else {
              lat = parseFloat(lat)
              long = parseFloat(long)
            }  
           
            this.range = this.$store.getters.range
            switch(this.range){
              case 0:
              this.zoom = 12
              break
              case 1:
              this.zoom = 13
              break
              case 2:
              this.zoom = 12
              break
              case 3:
              this.zoom = 12
              break   
              case 5:
              this.zoom = 11
              break  
              case 10:
              this.zoom = 10
              break  
              default:
              this.zoom = 9         
            }
           
            this.center = {lat:lat,lng:long}
            this.radius = (this.range/0.621371) * 1000
            let label = ''
            let add = ''
            this.markers = []    
            
            this.providers.forEach(element => {
                if(element.sp_gm_lat > 0){
                    lat = parseFloat(element.sp_gm_lat)
                    long = parseFloat(element.sp_gm_long)
                    label = {text:element.text,id:element.id}
                    add = element.add
                    this.markers.push({position:{lat:lat,lng:long},title:add,label:label})
                }
            });

            this.mapshow = true
            
           },

           getOptions() {
            return {
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false
            };
    },

            getWidth(){
              return{
                width: 100+'%',
                height: 400+'px'
              }
            },

        

          providerpopup(id){
            this.$router.push({ name: 'pagelisting', params: {id:id} }).catch(()=>{}) 
          },     
		}	   
	
	};
</script>