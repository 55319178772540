<template>
<div>
    <b-container style="padding-left:3%;padding-right:3%;z-index:9999;">
    <b-row>
        <b-col class="col-12 p-0">
            <b-input-group> 
            <b-form-input 
            type="search"
            v-model="searchtext" 
            autocomplete = "off"
            style="border-top-left-radius:20px;border-bottom-left-radius:20px"
            placeholder="What are you looking for?"
            @input="searchProvider"
            @keyup.enter="searchValues"
            size="lg"
            />
            <b-input-group-append style="background-color:#00709b;width:50px;padding:8px;border-top-right-radius:20px;border-bottom-right-radius:20px" >
                <b-icon-search font-scale="1.2" @click.stop="searchValues()" style="color:white;margin-top:8px;margin-left:8px" />
            </b-input-group-append>
            </b-input-group>
        </b-col>
    </b-row>
    
    <b-row  v-if="showResults" @mouseleave="mouseOut()">
        <b-col class="col-12 mt-1 pb-5 catslist" v-if="catslist.length > 0 && searchtext.length > 2" style="background-color:white">
            <ul>
            <li v-for="cat in catslist" :key="cat.page" @click="showlisting(cat.page)">{{ cat.text }}</li>
            </ul>

        </b-col >
        
        <b-col class="col-12 mt-1 catslist2">
            <b-btn variant="outline-info" block @click.stop="searchValues()" v-if="catslist.length > 0">show {{ catslist.length }} results</b-btn>
            <b-btn variant="outline-info" block disabled v-else>no results found for {{  searchtext }}</b-btn>
        </b-col>
    

    </b-row> 
</b-container>

</div> 
</template>

<style>
.catslist{
    position:absolute;
    z-index:9999;
    top:50px;
}

.catslist2{
    position:relative;
    z-index:9999;
    bottom:0;
    top:210px;
}

.catslist ul{
    min-height: 50px;
    max-height:200px;
    list-style-type: none;
    overflow-y: auto;
    padding-left:0;
}

.catslist li{
    padding:3px;
}
.catslist li:hover{
    background-color: #21598c;
    color:white;
    cursor: pointer;

}
</style>

<script>
import axios from 'axios'
export default {
        props:['home'],    
        data(){
        return {
            searchtext:'',
            searchtext2:'',
            catslist:[],
            postcode:'',
            search:false,
            showResults:false
            
            };
        },

mounted(){
   this.area = this.$store.getters.area
   this.postcode = this.$store.getters.postcode
   //this.range = this.$store.getters.range
},

       
methods: {

    searchValues(){
        this.showResults = false 
        this.$store.commit('setstext',this.searchtext)
        this.searchtext = ''
        
        if(this.$route.name != 'directory'){
            this.$router.push({ name: 'directory', params: {'catslist':this.catslist} }).catch(()=>{}) 
        } else this.$store.commit('setresults',this.catslist)
    },

    toggleSearch(){
        console.log('blur')
    },

    searchProvider(){
      
        if(this.searchtext.length > 2){

        axios.get('https://care4medev.co.uk/api/getproviderstextsearch',{
                    params:{
                        stext:this.searchtext,
                        range:this.$store.getters.range,
                        area:this.$store.getters.area,
                        cats:this.$store.getters.catsel,
                        postcode:this.postcode,
                        list:1
                        }
                })
				.then((response) => {
                    //this.$store.commit('setstext',this.searchtext)
                    this.showResults = true
                    this.catslist = response.data.Providers
                     })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
            }
        },

        mouseOut(){
            this.showResults = false
        },

        
            showlisting(lid){
                this.$router.push({ name: 'pagelisting', params: {id:lid} }).catch(()=>{}) 
            }

        }
};
</script> 