<template>
<div>
<b-container class="pagecont" style="margin-top:100px">

<b-row class="textbox" >
        <b-col class="col-12 col-sm-6" ref="mainbox">
            <h4 v-if="position.text == ''">Create new position</h4>
             <h4 v-else>Manage position</h4>
         </b-col>

          <b-col class="col-12 col-sm-6 mt-1 text-right">
               <h4 v-if="position.text != ''">{{ position.text }}</h4>
            <!-- <span :style="{backgroundColor:position.pcolor}" class="publishstate">
                {{ position.publishtext }} {{ position.publishdate }}
            </span> -->
        </b-col>
        
</b-row> 

<b-row class="mt-3">
   
</b-row>            

<b-row class="mt-2 mb-3" >
        <b-col class="col-12 col-sm-3 mt-3 text-center">
            <b-button block variant="outline-info" @click.stop="nextPage(1)"><b-icon-list-ul class="icon1" />Details</b-button>
        </b-col>  
     
        <b-col class="col-12 col-sm-3 mt-3 text-center">  
            <b-button block variant="outline-info" @click.stop="nextPage(2)"><b-icon-pin-map class="icon1" />Location</b-button>
        </b-col>
       
        <b-col class="col-12 col-sm-3 mt-3 text-center">    
            <b-button block variant="outline-info" @click.stop="nextPage(3)"><b-icon-calendar-date class="icon1" />Days/Times</b-button>
        </b-col>

         <b-col class="col-12 col-sm-3 mt-3 text-right">
            <b-button  variant="outline-info" @click="previewListing()" style="margin-right:10px" v-b-tooltip.hover title="Preview"><b-icon-eye class="icon" /></b-button>
            <b-button  variant="outline-danger" @click="previewListing()" v-b-tooltip.hover title="Delete"><b-icon-trash class="icon" /></b-button>
        </b-col>
      
</b-row>  

<b-row>

<b-col class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">

<b-container v-if="page==1" >

<b-row>
        <b-col class="col-12 col-sm-6 mt-3">
            <h3>Details</h3>
        </b-col>

        <b-col class="col-12 mt-3 mb-1">
            <h5>Title</h5>
                <b-form-input 
                    type="search" 
                    placeholder="eg: Shop sales assistant " 
                    autocomplete="off"
                    v-model="position.text"
                    name="position.text"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('position.text') }"  
                    class="darkinput"
                    @input = "validateField('position.text')"
                    />
                     <span style="color:red;">{{ errors.first('position.text') }}</span>
		</b-col>

        <b-col class="col-12 col-sm-6 mt-3">
            <h5>Start Date</h5>
                 <VueCtkDateTimePicker
                            v-model="position.start"
                            format='DD-MM-YYYY'
                            :dark="false"
                            :only-date="true"
                            :no-value-to-custom-elem="true"
                            >
                            <b-form-input 
                                type="search"
                                v-model="position.start" 
                                autocomplete = "off"
                                placeholder="select"
                                name="position.start"
                                v-validate="{required:true}"
                                :class="{'b-form-select': true, 'is-danger': errors.has('position.start') }"
                                />
                            </VueCtkDateTimePicker>    
            </b-col>

        <b-col class="col-12 col-sm-6 mt-3">
            <h5>End Date</h5>
                <VueCtkDateTimePicker
                            v-model="position.end"
                            format='DD-MM-YYYY'
                            :dark="false"
                            :only-date="true"
                            :no-value-to-custom-elem="true"
                            >
                            <b-form-input 
                                type="search"
                                v-model="position.end" 
                                autocomplete = "off"
                                placeholder="select"
                                name="position.start"
                                v-validate="{required:true}"
                                :class="{'b-form-select': true, 'is-danger': errors.has('position.end') }"
                                />
                            </VueCtkDateTimePicker>    
            </b-col>    

     
        
        <b-col class="col-12 mt-3"><h5>Description</h5></b-col>
        <b-col class="col-12 mt-3 mb-1">
                <mc-wysiwyg v-model="position.desc" :hide="hide"></mc-wysiwyg>
		</b-col>

        <b-col class="col-12 mt-3 mb-1">
            <h5>Contact Email</h5>
                <b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="position.email"
                    name="position.email"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('position.email') }"  
                    class="darkinput"
                    @input = "validateField('position.email')"
                    />
                     <span style="color:red;">{{ errors.first('position.email') }}</span>
		</b-col>

         <b-col class="col-12 mt-3 mb-1">
            <h5>Website</h5>
                <b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="position.website"
                    name="position.email"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('position.website') }"  
                    class="darkinput"
                    @input = "validateField('position.website')"
                    />
                     <span style="color:red;">{{ errors.first('position.website') }}</span>
		</b-col>

</b-row>



</b-container>

</b-col>
</b-row>

<b-container v-if="page==2" >

<b-row class="mt-3">
    <b-col class="col-8 mt-3">
        <h3>Location</h3>
    </b-col>

     <b-col class="col-12 col-sm-6 mt-3" ref="mainbox">
        <b-row>
            <b-col class="col-12 mt-3 mb-1">
                <h5>Building / House name</h5>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="position.add1"
                    name="position.provider_add1"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('position.add1') }"  
                    class="darkinput"
                    @input = "validateField('position.add1')"
                    />
                     <span style="color:red;">{{ errors.first('position.add1') }}</span>
            </b-col>

            <b-col class="col-12 mt-3 mb-1">
                <h5>Street</h5>
                <b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="position.add2"
                    name="position.add2"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('position.add2') }"  
                    class="darkinput"
                    @input = "validateField('position.add2')"
                    />
                     <span style="color:red;">{{ errors.first('position.add2') }}</span>
            </b-col>

            <b-col class="col-12 mt-3 mb-1">
                <h5>Area</h5>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="position.add3"
                    name="position.add3"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('position.add3') }"  
                    class="darkinput"
                    @input = "validateField('position.add3')"
                    />
                     <span style="color:red;">{{ errors.first('position.add3') }}</span>
            </b-col>

        
           
            <b-col class="col-12 col-sm-6 mt-3 mb-1">
              <h5>Postcode</h5>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="position.postcode"
                    name="position.postcode"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('position.postcode') }"  
                    class="darkinput"
                    @input = "validateField('position.postcode')"
                    @blur = getLatLong()
                    />
                     <span style="color:red;">{{ errors.first('position.postcode') }}</span>
                    <span style="color:red;" v-if="pcerror">Postcode not found</span>
            </b-col>

            
            <b-col class="col-12 col-sm-6 mt-3 mb-1">
              <h5>Phone</h5> 
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="position.phone"
                    class="darkinput"
                    />
            </b-col>
        </b-row>
     </b-col>

    <b-col class="col-12 col-sm-6 mt-3">
        <b-row>
           <b-col class="col-12">

            <div style="width:100%" v-if="display"> 

            <v-map
            ref="myMap"
            :center="[position.latitude,position.longitude]"
            :zoom="currentZoom"
            :options="mapOptions"
            :style="{height: mapheight + 'px', width: mapwidth + 'px'}"
            :noBlockingAnimations="blockanimation"
            >
            <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            <v-marker :lat-lng="[position.latitude,position.longitude]" v-if="position.provider_postcode !== ''">
              <v-icon :icon-anchor="staticAnchor">
                <img :src="mapmarker" style="height:30px;width:auto">
                <!-- <div class="headline" v-if="position.provider.provider_display_name != ''"><span translate="no">{{ position.provider.provider_display_name }}</span></div> -->
              </v-icon>
            </v-marker> 

            </v-map>

            </div>

           </b-col>

        </b-row>       

        </b-col> 
 
</b-row>


</b-container>

<b-row>
    <b-col class="col-12 col-md-10 offset-md-1">

<b-container v-if="page==3" >

<b-row class="mt-3">
    <b-col class="col-8 mt-3">
        <h3>Days / times required</h3>
    </b-col>
</b-row> 

 <b-row class="mt-3 mb-3">
        <b-col class="col-12">
        <b-table-simple hover small caption-top responsive>   
        <b-thead head-variant="dark">
        <b-tr>
            <b-th colspan="1"></b-th>
            <b-th v-for="day in days" :key="day.id" class="daytime">{{ day.text }}</b-th>
        </b-tr>

        </b-thead>

        <b-tbody>

        <b-tr >
            <b-td>Morning</b-td>
            <b-td v-for="day in days" :key="day.id" class="daytime" @click.stop="daySelect(day.id)">
                <span v-if="position.daytimes.includes(day.id)"><b-icon-check-circle /></span>
                <span v-else>.</span>
            </b-td>
        </b-tr> 

         <b-tr >
            <b-td>Afternoon</b-td>
            <b-td v-for="day in days" :key="day.id" class="daytime" @click.stop="daySelect(day.id + 7)">
                <span v-if="position.daytimes.includes(day.id + 7)"><b-icon-check-circle /></span>
                <span v-else>.</span>
            </b-td>
        </b-tr>   

         <b-tr >
            <b-td>Evening</b-td>
            <b-td v-for="day in days" :key="day.id" class="daytime" @click.stop="daySelect(day.id + 14)">
                <span v-if="position.daytimes.includes(day.id + 14)"><b-icon-check-circle /></span>
                <span v-else>.</span>
            </b-td>
        </b-tr>   

        </b-tbody>
        </b-table-simple>
        </b-col>
    </b-row>

</b-container>

</b-col>
</b-row>




    <b-row class="mt-5 mb-5">
       
        <b-col class="col-12 col-sm-4 offset-sm-2">
            <b-button class="mt-1"  size="lg" block variant="success" @click="validateAll()">
            <span v-if="position.value == 0">Submit position</span>
            <span v-else>Update position</span>
            </b-button>
        </b-col>

        <b-col class="col-12 col-sm-4 offset-sm-1">
            <b-button class="mt-1"  size="lg" block variant="warning" @click="goBack()">Back</b-button>
        </b-col>
        
    </b-row>    



<b-modal ref="show-position" size="md" :title="modaltitle"  hide-footer centered>
    <position-popup :position="position" />
</b-modal> 

</b-container>

</div>
</template>

<style scoped>
.headline{
  background-color:rgba(255,255,255,0.7);
  position: absolute;
  padding: 5px;
  min-width: 100px;
  border-radius: 10px;
}

.table .thead-dark th {
    background-color:#00709b !important;
}

.daytime{
    font-size:1.2em;
}

.daytime:hover{
    cursor:pointer;
}
</style>

<script>
import { McWysiwyg } from '@mycure/vue-wysiwyg'
import axios from 'axios'
import "leaflet/dist/leaflet.css"
import * as Vue2Leaflet from 'vue2-leaflet'
import { latLng } from "leaflet"
import { Icon } from 'leaflet'
import mapmarker from '@/assets/images/mapmarker.png'
import { Validator } from 'vee-validate'    

delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
  
  export default {
    components: {
        'v-map': Vue2Leaflet.LMap,
        'v-marker': Vue2Leaflet.LMarker,   
        'v-tilelayer': Vue2Leaflet.LTileLayer,
        'v-icon': Vue2Leaflet.LIcon,
        McWysiwyg 
    },
  data(){
    return{
            id:0,
            user:{},
            provider:{},
            position:{
                title:'',
                desc:'',
                start:'',
                end:'',
                website:'',
                email:'',
                add1:'',
                add2:'',
                add3:'',
                phone:''
            },
            logoimage:'',
            mapmarker,   
            lat:0,
            long:0,
            staticAnchor: [0,0],
            blockanimation:true,
            currentZoom:14,
            satlayergroup: {},
            currentCenter: latLng(51.45573031047103, -0.19369599774292554),
            setbounds:false,
            mapOptions: {
                zoomSnap: 0.5
                },
          
            mapwidth:300,
            mapheight:300,
            display:false,
            postcode:'',
            modaltitle:'',
            hide: {
                strikethrough: true,
                table: true,
                url: true
            },
            page:1,
            pcerror:false,
            days:[
                {
                id:1,
                text:'Mon'
                },
                {
                id:2,
                text:'Tue'
                },
                {
                id:3,
                text:'Wed'
                },
                {
                id:4,
                text:'Thu'
                },
                {
                id:5,
                text:'Fri'
                },
                {
                id:6,
                text:'Sat'
                },
                {
                id:7,
                text:'Sun'
                },
            ],   
          }
  },


  created(){ 
        window.scrollTo(0, 0);
        this.id = this.$route.params.id
        this.token = this.$store.getters.token
        if(this.id != 0){this.fetchPosition()} else this.fetchProvider()
            
        
  },

  mounted(){
        this.mapwidth = this.$refs.mainbox.clientWidth -50
        Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   
  },

  
  methods:{

    daySelect(id){
        let index  = this.position.daytimes.indexOf(id);
        if(index > -1){
            this.position.daytimes.splice(index, 1)
        } else this.position.daytimes.push(id)
        console.log(this.position.daytimes)
    },

    
      fetchPosition(){
                axios.get('/api/getvolpositions',{ 
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        id:this.id,
                    }
                })
				.then((response) => {
                    if(response == 999){
                        console.log(response.error)
                    } else {
                            this.position = response.data.Volunteers[0]
                            console.log(this.position)
                            this.display = true
                            }  
                    });
           }, 

    fetchProvider(){
                axios.get('/api/provider',{ 
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        id:this.$store.getters.user.provider,
                        //all:1
                    }
                })
				.then((response) => {
                    if(response == 999){
                        console.log(response.error)
                    } else {
                            let provider = response.data[0]
                            console.log(response.data[0])
                            this.position.add1 = provider.provider_add1
                            this.position.add2 = provider.provider_add2
                            this.position.add2 = provider.provider_add3
                            this.position.postcode = provider.provider_postcode
                            this.position.website = provider.provider_website
                            this.position.email = provider.provider_email
                            this.position.phone = provider.provider_phone
                            this.position.latitude = provider.latitude
                            this.position.longitude = provider.longitude
                            this.display = true
                            }
                    });
           },

   
     updatePosition(){

             axios({
                                method: 'post',     //   New Dealer
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/updateposition',
                                data: {
                                    data:this.position,
                                    }
                                })
                            .then((response)=> {

                            if(response.data.code == 999){
                                    alert('error')
                                } else {
                              
                                   console.log(response)
                                   
                                }
                        
                            });	
     },
     
      getLatLong(){

        if(this.position.postcode == this.postcode && this.position.postcode  == ''){
            console.log('no display')
            return}

            let apiKey = "ak_lm91i6dgahwp9TXWjQjvbIlcUWMUD"

                fetch(`https://api.ideal-postcodes.co.uk/v1/postcodes/${this.position.postcode}?api_key=${apiKey}`)
                .then(response => response.json())
                .then(data => {
                    if(data.message == 'Success'){
                    this.position.latitude = data.result[0].latitude
                    this.position.longitude = data.result[0].longitude;
                    this.currentCenter = latLng(this.position.latitude, this.position.longitude)
                    this.pcerror = false
                    } else  this.pcerror = true
                })
                .catch(error => {
                    console.error("Error:", error);
                });    
    },

           
    LogofileUploaded(file){
                this.logoimage = file
    },
            
    resetLogo(){
            this.logoimage = '' 
    },

    nextPage(page){
        if(page != null){
            this.page = page
        } else this.page++
    },

    prevPage(){
        this.page--
    },
      
    validateAll(){
            this.$validator.validateAll().then(result => {
                   if(result){
                       this.updatePosition()
                       } else alert('error')
               })
    },

    validateField(field){
              this.$validator.validate(field)
              if(field == 'listing.postcode'){this.pcerror = false}
    },

    previewListing(){
           this.modaltitle = 'Position Preview'
           this.$refs['show-position'].show() 
    },

    goBack(){
          this.$router.push({ name: 'mypositions', params: {} }).catch(()=>{})
    }

    
  }            
}
</script>