import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Vuex from 'vuex'
Vue.use(Vuex)

import axios from 'axios';
axios.defaults.baseURL = 'https://care4medev.co.uk';
Vue.use(Vuex)

import 'bootstrap'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
      key: 'AIzaSyDvLurkziD0HkRwcuEfUt9GH6UYaSxhLjE', //AIzaSyBAonmxXvojRhlH8WlOaSO4JoBNgwmxjes',
      // This is required to use the Autocomplete plugin
      libraries: 'places', loading: 'async' // 'places,drawing,visualization'
  },
});


import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@sweetalert2/theme-dark/dark.min.css';
Vue.use(VueSweetalert2)

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate, { 
	fieldsBagName: 'veeFields',
	events: ''     
});

Vue.config.productionTip = false

import HomePage from './components/HomePage.vue'
import HelpSupport from './components/HelpSupport.vue'
import DirectoryPage from './components/DirectoryPage.vue'
import RegisterPage from './components/RegisterPage.vue'
import MyCare4Me from './components/MyCare4me.vue'
import MyListings from './components/MyListings.vue'
import PageListing from './components/PageListing.vue'
import MyPositions from './components/MyPositions.vue'
import ManageListing from './components/ManageListing.vue'
import ManagePosition from './components/ManagePosition.vue'
import VolunteerPositions from './components/VolunteerPositions.vue'

Vue.component('home-landing', require('./components/HomeLanding.vue').default)
Vue.component('side-cats', require('./components/SideCats.vue').default)
Vue.component('navbar-main', require('./components/NavBar.vue').default)
Vue.component('search-box', require('./components/SearchBox.vue').default)
Vue.component('display-results', require('./components/DisplayResults.vue').default)
Vue.component('provider-popup', require('./components/ProviderPopup.vue').default)
Vue.component('position-popup', require('./components/PositionPopup.vue').default)
Vue.component('show-map', require('./components/ShowMap.vue').default)
Vue.component('user-profile', require('./components/UserProfile.vue').default)
Vue.component('image-uploader', require('./components/ImageUploader.vue').default)

const router = new VueRouter({    
	
  mode: 'history',
  
  scrollBehavior() {
    return { top: 0, left: 0 }
  },
  
 routes: [
 
  
  {
    path: '/', 
    name: 'home',
    component: HomePage,    
  },
  {
    path: '/directory', 
    name: 'directory',
    component: DirectoryPage,  
  },
  {
    path: '/register', 
    name: 'register', 
    component: RegisterPage,  
    },
    {
      path: '/helpsupport', 
      name: 'helpsupport', 
      component: HelpSupport,  
      },
    {
      path: '/mycare4me', 
      name: 'mycare4me', 
      component: MyCare4Me,  
      },
      {
        path: '/listing/:id', 
        name: 'pagelisting',  
        component: PageListing,  
        },
      
    {
      path: '/mylistings', 
      name: 'mylistings', 
      component: MyListings,  
      },
        
    {
      path: '/managelisting/:id', 
      name: 'managelisting', 
      component: ManageListing,  
      },
    {
        path: '/mypositions', 
        name: 'mypositions', 
        component: MyPositions,  
      },
      {
        path: '/manageposition/:id', 
        name: 'manageposition', 
        component: ManagePosition,  
        },
       
      {
      path: '/volunteerpositions', 
      name: 'volunteerpositions', 
      component: VolunteerPositions,  
      }, 

]

})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

