<template>
<div>
  <b-row class="textbox" ref="main">
        <b-col class="col-12 col-md-12 col-xl-8 offset-xl-2 mt-2">
          <h4>Directory</h4>
         </b-col>
   </b-row>   

   <b-container class="pagecont">

   <b-row class="mt-3">

    <b-col class="col-12 col-md-12 col-lg-3 col-xl-3 offset-xl-1" v-show="!mobile">
      <SideCats :update="update"/>
    </b-col>

    <b-col class="col-12 p-0 m-0" v-show="mobile">
      <SideCatsMobile />
    </b-col>

    <!-- <b-col class="col-12 mt-3" v-show="!mobile">
              <b-btn variant="outline-info" block size="sm" @click.stop="toggleFilters()" v-if="filterdisplay"><b-icon-map style="margin-right:10px"/> Hide Filters</b-btn>
              <b-btn variant="outline-info" block size="sm" @click.stop="toggleFilters()" v-else><b-icon-map style="margin-right:10px" /> Show Filters</b-btn>
    </b-col> -->

 
    <b-col class="col-12 col-md-12 col-lg-8 offset-lg-1 col-xl-6  vt-100">

          <b-row class="mt-1" >
              <b-col class="col-12 mb-3"  v-if="mapdisplay">
                <ShowMap style="margin-bottom:0px"/>
              </b-col>  
              
              <b-col class="col-12 mt-3 m-0 p-0">
              <b-btn variant="outline-info" block size="sm" @click.stop="toggleMap()" v-if="mapdisplay"><b-icon-map style="margin-right:10px"/> Hide Map</b-btn>
              <b-btn variant="outline-info" block size="sm" @click.stop="toggleMap()" v-else><b-icon-map style="margin-right:10px" /> Show Map</b-btn>
            </b-col>
          </b-row>

          <b-row>
                <b-col class="col-12 mt-3 m-0 p-0"> 
                  <DisplayResults v-on:catupdate="updateCats()"/> 
                </b-col>
          </b-row>     
      
      </b-col>
      
    </b-row>
   
</b-container>

</div>
</template>

<script>
import SideCats from './SideCats.vue'
import SideCatsMobile from './SideCatsMobile.vue'
import ShowMap from './ShowMap.vue'
import DisplayResults from './DisplayResults.vue'
import { mapState } from 'vuex'
export default {
  components: { 
   SideCats,
   SideCatsMobile,
   ShowMap,
   DisplayResults
   },
  data(){
    return{
          //mapwidth:0,
          display:false,
          mapdisplay:false,
          filterdisplay:false,
          showsearch:false,
          modaltitle:'',
          update:false
     }
  },

  
  mounted(){

    if(this.$route.params != ''){
      this.$store.commit('setresults',this.$route.params.catslist)
    }    
    //this.mapwidth = this.$refs.secbox.clientWidth
    this.mapdisplay = this.$store.getters.mapshow
      },

    computed: mapState(['mobile','mappoints']),
    
    watch: {

      mappoints(){
          if(this.mappoints.length == 0){
            this.mapdisplay = false
          } else this.mapdisplay = true

      }
    },  
  
    methods:{

      toggleMap(){
        this.mapdisplay = !this.mapdisplay
        this.$store.commit('setmapshow',this.mapdisplay)
      },

      toggleFilters(){
        this.filterdisplay = !this.filterdisplay
      },

      toggleSearch(){
        this.modaltitle = 'Search'
        this.showsearch = !this.showsearch
        if(this.showsearch){
          this.$refs['show-search'].show()
        }
        
      },

      updateCats(){
        this.update = !this.update
      }
    }
    
}            
</script>