<template>
<div>

<b-container class="addrowpage" v-if="!edit">
    <b-row class="mt-3">

                <b-col class="col-12 mt-1">
                    <b-icon-person-circle class="icon1" />{{ user.fname }} {{ user.lname }}
                </b-col>

                <b-col class="col-12 mt-1">
                    <b-icon-telephone class="icon1" />{{ user.mobile }}
                </b-col>

                <b-col class="col-12 mt-1">
                    <b-icon-envelope class="icon1" />{{ user.email }}
                </b-col>
            
    </b-row> 
   
    <b-row class="mt-5">
        <b-col class="col-12">
            <b-button variant="outline-success" block ref="next" @click="edit = !edit">Edit</b-button>
        </b-col>  
    </b-row>   

    </b-container>

    <b-container class="addrowpage" v-else>

    <b-row>
      
		<b-col class="col-12 mt-3">
                <label>First Name</label>
				<b-form-input 
                    type="search" 
                    placeholder="first name" 
                    autocomplete="off"
                    v-model="user.fname"
                    name="user.fname"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('user.fname') }"  
                    class="darkinput"
                    @input = "validateField('user.fname')"
                    />
                     <span style="color:red;">{{ errors.first('user.fname') }}</span>
		</b-col>

		<b-col class="col-12 mt-3">
                <label>Last Name</label>
				<b-form-input 
                    type="search"
                    placeholder="last name"
                    autocomplete="off" 
                    v-model="user.lname" 
                    name="user.lname"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('user.lname') }"
                    class="darkinput"
                    @input = "validateField('user.lname')"  
                    />
                     <span style="color:red;">{{ errors.first('user.lname') }}</span>
		</b-col>

    
        <b-col class="col-12 mt-3">
                <label>Email</label>
				<b-form-input 
                    type="search"
                    placeholder="login email"
                    autocomplete="off" 
                    v-model="user.email" 
                    name="user.email"
                    v-validate="'required|customRule:'+emailerror"
					:class="{'input': true, 'is-danger': errors.has('user.email') }"
                    class="darkinput"
                    @input = "validateField('user.email')"
                    @change = "validateField('user.email')"
                    />
                    <span style="color:red;">{{ errors.first('user.email') }}</span>
		</b-col>

        <b-col class="col-12 mt-3">
                <label>Phone</label>
				<b-form-input 
                    type="search"
                    placeholder="mobile or telephone"
                    autocomplete="off" 
                    v-model="user.mobile" 
                    class="darkinput"  
                    />
		</b-col>

        <b-col class="col-12 mt-3" v-if="user.value !== undefined">
                <label>Change Password</label>
				<b-form-input 
                    type="search"
                    placeholder="new password"
                    autocomplete="off" 
                    v-model="user.changepassword" 
                    class="darkinput"  
                    />
		</b-col>
		
</b-row>
   
     <b-row class="mt-5 mb-3">
         <b-col class="col-12">
            <b-button variant="outline-success" block  @click="updateUser()">UPDATE PROFILE</b-button>
         </b-col>
    </b-row> 

 </b-container>

		
</div> 
</template>


<script>
    import { Validator } from 'vee-validate'
    import './customRule'
    import axios from 'axios'
	export default {
        data(){
		return {
                token:'',
                user:{},
                version:'-',
                currentemail:'',
                emailerror:false,
                edit:false,
                userclass:'stagecomp',
                editclass:'stagecomp',
                createclass:'stagecomp',
                line1class:'stageselectlinecomp',
                line2class:'stageselectlinecomp',
                display:false
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.version = this.$store.getters.version
            this.fetchUser()
        },

         mounted() {
            Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   
        },

      
		methods: {

            fetchUser(){
                axios.get('/api/users',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:this.$store.getters.user.id,
                        //all:1
                    }
                })
				.then((response) => {
                    this.user = response.data.users[0]
                    this.currentemail = this.user.email
                    this.user.changepassword = ''
                    this.display = true
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
           },

       
           validateUser(){
               this.$validator.validateAll().then(result => {
                   if(result){
                       if(this.user.changepassword !== ''){this.user.password = this.user.changepassword}
                      
                       }
               })
           },

            validateField(field){
               setTimeout(() => this.validateDebounced(field), 300)
            },

            validateDebounced(field){
                this.$validator.validate(field)
                if(field == 'user.email'){this.emailCheck()}
           },

            emailCheck(){
                axios.get('/api/checkuserexists',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         email:this.user.email
                    }
                })
				.then((response) => {
                  
                      if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                  
                    if(response.data.exists == true){
                        this.emailerror = true
                        } else {
                            this.emailerror = false //this.$emit('dealer',this.dealer)
                            }
                      } 
                      
                    });    
           },

          updateUser(){

                 if(this.user.email !== this.currentemail){
                        if(!this.emailCheck()){return}
                    }

                this.$validator.validateAll().then(result => {
                    if (result){
               
                        axios({
                                method: 'put',
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/casteruserprofile',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    userdata:this.user
                                    }
                                })
                            .then((response)=> {
                       
                            if(response.data.error){
                                this.saving = false
                                this.$swal({
                                                title: "Update Error",
                                                text: response.data.error,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                } else {

                                    this.$swal({
                                                title: "Updated",
                                                text: 'Profile updated',
                                                icon: "success",
                                                showCloseButton: true,
                                                showConfirmButton: false,
                                                timer:3000
                                                })
                                                .then(() => {
                                                this.saving = false
                                                this.$emit('updatemodal')
                                                }) 

                                }

                        
                            }).catch(err => {
                                        this.saving = false
                                        this.$swal({
                                                title: "Update Error",
                                                text: err,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                        });	
                        }
                });
          },               
                    
         updatemodal(){
              this.$emit('updatemodal')
         },

         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script>