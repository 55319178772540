<template>
<div class="mainpage">

    <b-row class="textbox">
        <b-col class="col-12 col-md-12 col-xl-8 offset-xl-2 mt-2">
            <h4>Help & Support</h4>
        </b-col>
    </b-row> 

    <b-container class="pagecont">    
     <b-row>
        <b-col class="col-12 col-md-8 offset-md-2"> 

      <b-row>
          <b-col class="col-12 mt-5">
          <h4>Can’t find what you are looking for?</h4>

          <p>If you would like help finding services on Care4me please contact the Wandsworth Wellbeing Hub on the details below, to speak to one of their Community Navigators who will be happy to give you details of local services available in Wandsworth.</p>
          <h5>Tel: 020 3880 0366 (Mon-Fri 9:00-17:00)</h5>
          <h5>Email: waccg.wandsworthhub@nhs.net</h5>
          
          </b-col>

          <b-col class="col-12 mt-3">
          <h4>Do you need help with your registration, listing and volunteering on Care4me or to speak to one of our staff?</h4>
          <h5>Contact care4me team on the details below</h5>
          </b-col>
          <b-col class="col-12 mt-3">
          <h5>Tel: 0800 433 7175</h5>
          <h5>Email: info@care4me.org.uk</h5>

          </b-col>
      </b-row>

      <b-row class="mt-5">
          <b-col class="col-12 mt-2">
          <h4>Feedback</h4>
          <p>If you have any comments about the site or require support with updating your own listing, please get in touch by filling out the form below. We welcome your feedback.</p>
          </b-col>
      </b-row>    

   <b-row class="mt-2">

        <b-col class="col-12 col-sm-6">
        <b-row>    
   
          <b-col class="col-12 col-sm-6 mt-3">
                <label>First Name</label>
                <b-form-input
                v-model="contact.fname"
                placeholder=" "
                prepend-icon="mdi-account-outline"
                required

                />
          </b-col>

          <b-col class="col-12 col-sm-6 mt-3">
            <label>Last Name</label>
              <b-form-input 
                  v-model="contact.lname"
                  placeholder=" "
                  prepend-icon="mdi-account-outline"
                  required

                  />
          </b-col>

    
        <b-col class="col-12 mt-3">
            <label>E-Mail Address</label>
            <b-form-input 
                label="E-Mail Address"
                v-model="contact.email"
                placeholder=" "
                :rules="emailRules"
                autocomplete="contact.email"
                prepend-icon="mdi-email-outline"
                required

                />
        </b-col>

        <b-col class="col-12 mt-3">
                <label>Message</label>
                <b-textarea
                    label="Message"
                    v-model="contact.text"
                    placeholder="Your message..."
                    required
            />

        </b-col>


        </b-row>
        </b-col>
    </b-row>
    
   
   <b-row class="mt-5 mb-5">
      <b-col class="col-12 col-sm-6">
        <b-btn  @click.stop="registerUser()">Submit</b-btn>
      </b-col>
   </b-row>      

</b-col>
</b-row>   

</b-container>

<Site-Footer />

</div>
</template>

<script>
import SiteFooter from'./SiteFooter.vue'
import axios from 'axios'
import { Validator } from 'vee-validate'
export default {
  components:{
    SiteFooter
  },
  data(){
    return{
          contact:{
            fname:'',
            lname:'',
            email:'',
            text:''
          },
          emailRules: [
                v => !!v || 'E-mail is required',
               // v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
          emailerror:false
      }
  },

  mounted() {
            Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   
        },    
  methods:{

      registerUser(){
         axios({
              method: 'post',    
              headers: {"Authorization" : "Bearer " + this.token},
              url: '/api/register',
              data: {
                  register:this.register
                  }
              })
               .then((response)=> {

                if(response.data.code == 999){
                      this.errorresponse = response.data
                      this.saving = false
                  } else {
                
                      console.log(response.data)
                      
                      //   this.sendEmail(response.data)
                      
                  }
      
              });	
            },
    
  }            
}
</script>