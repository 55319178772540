<template>
<div>

<b-row class="textbox" >
    <b-col class="col-12 col-sm-6 col-xl-4 offset-xl-2 mt-2" ref="mainbox">
          <h4>Volunteer Positions</h4>
    </b-col>
</b-row>  

<b-container class="pagecont">

<b-row>
    <b-col class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">

    <b-row>    
        <b-col class="col-12  mt-3"> 
          <b-button variant="outline-info" size="md"  block @click.stop="newPosition()"><b-icon-plus-circle class="icon1" />Create new position</b-button> 
        </b-col>
    </b-row>    

<b-row class="mt-3 mb-3 pb-3" style="background-color:#fafafa" v-for="position in positions" :key="position.value">
      
        <b-col class="col-6 mt-3">
            <h5>{{ position.text }}</h5>
        </b-col>

        <b-col class="col-6 mt-3 text-center text-sm-right">
            <b-button variant="outline-info" size="sm" style="margin-right:5px" @click.stop="showPreview(position)" v-b-tooltip.hover title="Preview listing"><b-icon-eye class="icon2" /></b-button> 
            <b-button variant="outline-success" size="sm" style="margin-right:5px" @click.stop="editPosition(position.value)" v-b-tooltip.hover title="Edit listing"><b-icon-pencil class="icon2" /></b-button> 
            <b-button variant="outline-danger" size="sm" v-b-tooltip.hover title="Delete listing"><b-icon-trash class="icon2" /></b-button>
        </b-col>    
 
      
        <b-col class="col-12 col-sm-6 mt-3">
             <h5>Listed: {{ position.posted }}</h5>
        </b-col>

        <b-col class="col-12 col-sm-6 mt-3 text-right">
           
            <img v-if="position.provider.provider_logo !== null" class="provider_logo" :src="`https://care4medev.co.uk/images/logos/${position.provider.provider_logo}` ">
        </b-col>

        
        <b-col class="col-12 mt-3">
            <h6>{{ position.desc }}</h6>
        </b-col>
  
        <b-col class="col-12">
        <b-table-simple hover small caption-top responsive>   
        <b-thead head-variant="dark">
        <b-tr>
            <b-th colspan="1"></b-th>
            <b-th v-for="day in days" :key="day.id" class="daytime">{{ day.text }}</b-th>
        </b-tr>

        </b-thead>

        <b-tbody>

        <b-tr >
            <b-td>Morning</b-td>
            <b-td v-for="day in days" :key="day.id" class="daytime">
                <span v-if="position.daytimes.includes(day.id)"><b-icon-check-circle /></span>
                <span v-else>.</span>
            </b-td>
        </b-tr> 

         <b-tr >
            <b-td>Afternoon</b-td>
            <b-td v-for="day in days" :key="day.id" class="daytime">
                <span v-if="position.daytimes.includes(day.id + 7)"><b-icon-check-circle /></span>
                <span v-else>.</span>
            </b-td>
        </b-tr>   

         <b-tr >
            <b-td>Evening</b-td>
            <b-td v-for="day in days" :key="day.id" class="daytime">
                <span v-if="position.daytimes.includes(day.id + 14)"><b-icon-check-circle /></span>
                <span v-else>.</span>
            </b-td>
        </b-tr>   

        </b-tbody>
        </b-table-simple>
        </b-col>
  
        <b-col class="col-12 col-sm-6">

          <b-row class="mt-3">
            <b-col class="col-12" v-if="position.phone !== ''"><b-icon-telephone class="icon1" />{{ position.phone }}</b-col>
            <b-col class="col-12" v-if="position.email !== ' '"><b-icon-envelope class="icon1" />{{ position.email }}</b-col>
            <b-col class="col-12" v-if="position.provider.provider_website !== ''"><b-icon-globe class="icon1" />{{ position.provider.provider_website }}</b-col>
        </b-row>   

        <b-row class="mt-3">
            <b-col class="col-12">
                <span v-if="position.add1 != ''">{{ position.add1 }}<br/></span>
                <span v-if="position.add2 != ''">{{ position.add2 }}<br/></span>
                {{ position.add3 }}<br/>
                {{ position.postcode }}<br/>
            </b-col>
        </b-row>

        </b-col>
        <b-col class="col-12 col-sm-6">

        <b-row class="mt-3">
        <b-col class="col-12 m-0 p-0" v-if="display">

            <div style="width:100%"> 

            <v-map
            v-if="display"
            ref="myMap"
            :center="[position.latitude,position.longitude]"
            :zoom="currentZoom"
            :options="mapOptions"
            :style="{height: mapheight + 'px', width: mapwidth + 'px'}"
            :noBlockingAnimations="blockanimation"
           
            >
          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            <v-marker
               
                :lat-lng="[position.latitude,position.longitude]"
               
               >
              <v-icon      
                                :icon-anchor="staticAnchor"
                                >
                                <img :src="mapmarker" style="height:30px;width:auto">
                                <div class="headline"><span translate="no">{{ position.provider.provider_display_name }}</span></div>
              </v-icon>
            </v-marker> 

           
          </v-map>

            </div>

        </b-col>
       
        </b-row>  

        </b-col>

        <b-col class="col-12 text-center">
            <hr/>
        </b-col>
    </b-row>
  
    </b-col>
 </b-row>   

<b-modal ref="show-position" size="md" :title="modaltitle"  hide-footer centered>
    <position-popup :position="positionsel" />
</b-modal> 

</b-container>

</div>
</template>

<style scoped>
.headline{
  background-color:rgba(255,255,255,0.7);
  position: absolute;
  padding: 5px;
  min-width: 100px;
  border-radius: 10px;
}

 
</style>

<script>
import axios from 'axios'
import "leaflet/dist/leaflet.css"
import * as Vue2Leaflet from 'vue2-leaflet'
import { latLng } from "leaflet"
import { Icon } from 'leaflet'
import mapmarker from '@/assets/images/mapmarker.png'

  delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
  
  export default {
    components: {
      'v-map': Vue2Leaflet.LMap,
      'v-marker': Vue2Leaflet.LMarker,   
      'v-tilelayer': Vue2Leaflet.LTileLayer,
     // 'v-popup': Vue2Leaflet.LPopup,
      'v-icon': Vue2Leaflet.LIcon 
    },
  data(){
    return{
            user:{},
            provider:{},
            positionsel:{},
            positions:[],
            display:true,
            mapmarker,
            modaltitle:'',   
            staticAnchor: [0,0],
            blockanimation:true,
            currentZoom:12,
            satlayergroup: {},
            currentCenter: latLng(51.45573031047103, -0.19369599774292554),
            setbounds:false,
            mapOptions: {
                zoomSnap: 0.5
                },
          
            mapwidth:300,
            mapheight:300,
             days:[
                {
                id:1,
                text:'Mon'
                },
                {
                id:2,
                text:'Tue'
                },
                {
                id:3,
                text:'Wed'
                },
                {
                id:4,
                text:'Thu'
                },
                {
                id:5,
                text:'Fri'
                },
                {
                id:6,
                text:'Sat'
                },
                {
                id:7,
                text:'Sun'
                },
            ],  
         
          }
  },


  created(){ 
       
        this.user = this.$store.getters.user
        this.fetchPositions()
  },

  mounted(){
        this.mapwidth = this.$refs.mainbox.clientWidth
  },
  
  methods:{

      editPosition(id){
          this.$store.commit('setYPosition',window.scrollY)
          this.$router.push({ name: 'manageposition', params: {id:id} }).catch(()=>{}) 
      },

      newPosition(){
         this.$router.push({ name: 'manageposition', params: {id:0} }).catch(()=>{}) 
      },

      showPreview(event){
           this.modaltitle = event.provider.provider_display_name
           this.positionsel = event
           this.$refs['show-position'].show()
      },

      scrollY() {
        setTimeout(()=>{
            window.scrollTo(0, this.$store.getters.YPosition);
             }, 400)
     
       },     
    
      fetchPositions(){
                axios.get('/api/getvolpositions',{ 
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        //id:this.$store.getters.user.provider,
                        //all:1
                    }
                })
				.then((response) => {
                    if(response == 999){
                        console.log(response.error)
                    } else {
                            this.positions = response.data.Volunteers
                            this.scrollY()
                            console.log(this.positions)
                         
                            }
                    });
           },
           
    
  }            
}
</script>