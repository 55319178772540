<template>
<div style="padding:3%">
      <b-row >
                <b-col class="col-12 text-right" id="mapbox" >
                     <img v-if="position.provider.provider_logo !== null" class="provider_logo" :src="`https://care4medev.co.uk/images/logos/${position.provider.provider_logo}` ">
                </b-col>

                <b-col class="col-12">
                <h3><span translate="no">{{ position.text }}</span></h3>
                <div v-html="position.desc" />
                </b-col>
                
        </b-row>

        <b-row class="mt-3 mb-3">
        <b-col class="col-12">
        <b-table-simple>   
        <b-thead>
            <b-tr>
                <th colspan="1"></th>
                <th v-for="day in days" :key="day.id" class="daytime">{{ day.text }}</th>
            </b-tr>
        </b-thead>

        <b-tbody>

        <b-tr >
            <b-td>Morning</b-td>
            <b-td v-for="day in days" :key="day.id" class="daytime">
                <span v-if="position.daytimes.includes(day.id)"><b-icon-x-circle class="icon1" /></span>
                <span v-else>.</span>
            </b-td>
        </b-tr> 

         <b-tr >
            <b-td>Afternoon</b-td>
            <b-td v-for="day in days" :key="day.id" class="daytime">
                <span v-if="position.daytimes.includes(day.id + 7)"><b-icon-x-circle class="icon1" /></span>
                <span v-else>.</span>
            </b-td>
        </b-tr>   

         <b-tr >
            <b-td>Evening</b-td>
            <b-td v-for="day in days" :key="day.id" class="daytime">
                <span v-if="position.daytimes.includes(day.id + 14)"><b-icon-x-circle class="icon1" /></span>
                <span v-else>.</span>
            </b-td>
        </b-tr>   

        </b-tbody>
        </b-table-simple>

        </b-col>
    </b-row>

          <b-row class="mt-3">
            <b-col class="col-12" v-if="position.phone !== ''"><b-icon-telephone class="icon1" />{{ position.phone }}</b-col>
            <b-col class="col-12" v-if="position.email !== ' '"><b-icon-envelope class="icon1" />{{ position.email }}</b-col>
            <b-col class="col-12" v-if="position.website !== ''"><b-icon-globe class="icon1" />{{ position.website }}</b-col>
        </b-row>   

        <b-row class="mt-3">
            <b-col class="col-12">
                <span v-if="position.add1 != ''">{{ position.add1 }}<br/></span>
                <span v-if="position.add2 != ''">{{ position.add2 }}<br/></span>
                {{ position.add3 }}<br/>
                {{ position.postcode }}<br/>
            </b-col>
        </b-row>

       
        <b-row class="mt-3">
        <b-col class="col-12 m-0 p-0" v-if="display">

            <div style="width:100%"> 

            <v-map
            v-if="display"
            ref="myMap"
            :center="currentCenter"
            :zoom="currentZoom"
            :options="mapOptions"
            :style="{height: mapheight + 'px', width: mapwidth + 'px'}"
            :noBlockingAnimations="blockanimation"
           
            >
          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            <v-marker
               
                :lat-lng="[lat,long]"
               
               >
              <v-licon      
                                :icon-anchor="staticAnchor"
                                >
                                <img :src="mapmarker" style="height:30px;width:auto">
                                <div class="headline"><span translate="no">{{ position.provider.provider_display_name }}</span></div>
                           
              </v-licon>

             

             </v-marker> 

           
          </v-map>

            </div>

        </b-col>
    </b-row>
   
</div> 
</template>

<style scoped>
.headline{
  background-color:rgba(255,255,255,0.7);
  position: absolute;
  padding: 5px;
  min-width: 100px;
  border-radius: 10px;
}

.table .thead-dark th {
    background-color:#00709b !important;
}
</style>

<script>
   import "leaflet/dist/leaflet.css"
   import * as Vue2Leaflet from 'vue2-leaflet'
   import { latLng } from "leaflet"
   import { Icon } from 'leaflet'
   import mapmarker from '@/assets/images/mapmarker.png'

    delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
  
  export default {
    components: {
      
      'v-map': Vue2Leaflet.LMap,
      'v-marker': Vue2Leaflet.LMarker,   
      'v-tilelayer': Vue2Leaflet.LTileLayer,
     // 'v-popup': Vue2Leaflet.LPopup,
      'v-licon': Vue2Leaflet.LIcon
    },
        props:['position'],    
		data(){
        return {
                mapmarker,  
                lat:0,
                long:0,
                staticAnchor: [0,0],
                blockanimation:true,
                currentZoom:12,
                satlayergroup: {},
                currentCenter: latLng(51.45573031047103, -0.19369599774292554),
                setbounds:false,
                mapOptions: {
                    zoomSnap: 0.5
                  },
                display:false,  
                mapwidth:300,
                mapheight:300,
                days:[
                {
                id:1,
                text:'Mon'
                },
                {
                id:2,
                text:'Tue'
                },
                {
                id:3,
                text:'Wed'
                },
                {
                id:4,
                text:'Thu'
                },
                {
                id:5,
                text:'Fri'
                },
                {
                id:6,
                text:'Sat'
                },
                {
                id:7,
                text:'Sun'
                },
            ],  
           };
        },

       
        mounted(){
         
            setTimeout(()=>{
              this.mapwidth = document.getElementById('mapbox').clientWidth -30
              this.lat = this.position.provider.latitude
              this.long = this.position.provider.longitude
              this.currentCenter = latLng(this.lat,this.long)
              
              this.display = true
              console.log(this.position) 

             }, 400)
      
        },
       
        methods: {

        }  
	
};

</script> 