<template>
<div>



            <b-row>       
                    <b-col class="col-12">
                        <!-- <b-form-file 
                                    ref="file-input"
                                    single
                                    v-model="image" 
                                    placeholder="Choose logo image (.jpeg, .jpg, .png)"
                                    drop-placeholder="Drop file here..."
                                    accept= ".jpeg, .jpg, .png"
                                    class="darkinput"  
                                    /> -->
                    </b-col>
                   
            </b-row>
    


		
</div> 
</template>


<script>

    import axios from 'axios'
	export default {

        data(){
		return {
    
                token:'',
                image: null,
                lockfield:false,
                };
                
                },

		created(){
          
            this.token = this.$store.getters.token
        
        },

         watch: {
                image: function () {
                 
				if(this.image !== null){
					this.uploadImage(this.image) 
					}  
                }
        },
        
        methods: {

          
            uploadImage(image){

                 const formData = new FormData
                    formData.append('files', image);
                    formData.append('token',this.$store.getters.user.token)
                    // ???? self = this
                   
                    axios({
                            method: 'post',
                            headers: {"Authorization" : "Bearer " + this.token},
                            url: '/api/imageupload',
                            data: formData
                            })
                            .then((response)=>{
                                this.$refs['file-input'].reset()
                                this.$emit('upload',response.data.filename)
                        }).catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });

           }, 

        updatemodal(){
              this.$emit('updatemodal')
         },
   
		}	   
	
	};

</script> 