<template>
<div>

  <b-row class="textbox" ref="main">
        <b-col class="col-12 col-md-12 col-xl-8 offset-xl-2 mt-2">
          <h4>Welcome</h4>
         </b-col>
   </b-row>

<b-container class="headerimage">
  <b-row style="height:600px;width:100%" align-v="center" >
    <!-- <b-col class="col-12 col-sm-8 offset-sm-2">
    <SearchBar :home=true />
    </b-col> -->
  </b-row>    
</b-container>

<b-container class="xpagecont" style="margin-top:50px">

   <b-row class="xtextbox">
        
        <b-col class="col-12" align="center">
          <h3>Welcome to Care4me - your online community directory</h3>
        </b-col>

        <b-col class="col-12 col-sm-10 offset-sm-1 mt-3">    
           <p>Care4Me.org.uk is a website that provides information and support for carers in the London boroughs of Lambeth, Southwark, and Lewisham.<br/><br/>The website is run by a charity called Lifetimes, which is part of a network of carers' centers in the UK.</p>
        </b-col>

        <b-col class="col-12 col-sm-10 offset-sm-1 mt-3">    
        <p>Carers' Hub Lambeth works to provide information, advice, and support to carers, as well as to raise awareness about the needs and rights of carers in the community.<br/><br/>If you are a carer in one of these London boroughs, you may find the resources and support provided by Care4Me.org.uk to be helpful in your caregiving journey.</p>
        </b-col>

   </b-row>     

  <b-row class="mt-5 d-none d-sm-flex">
        
        <b-col class="col-12 col-md-3 catbox text-center" v-for="cat in cats" :key="cat.id" @click.stop="selectCat(cat)">
            <div class="catboxcont"> 
                <img :src="`https://care4medev.co.uk/storage/buttons/${cat.image}` " >
                <div class="cattextbox">{{ cat.text }}</div>
            </div>    
        </b-col>

  </b-row> 

  <b-row class="mt-3 d-flex d-sm-none catrow" v-for="cat in cats" :key="cat.id" @click.stop="selectCat(cat)">

        <b-col class="col-3"><img :src="`https://care4medev.co.uk/storage/buttons/${cat.image}` " class="catimage"></b-col>
        <b-col class="col-9">{{ cat.text }}</b-col>
      
  </b-row> 

  <b-row class="mt-5 mb-5">

    <b-col class="col-12 col-md-6 p-3" >
      <b-row  class="bluehalf">
        <b-col class="col-12 col-sm-8">
        <h5>Providing a local community service?</h5>
        </b-col>
        <b-col class="col-12 col-sm-4">
        <b-btn block variant="light" @click.stop="menuLink('register')">Register</b-btn>
        </b-col>
      </b-row>
    </b-col>

    <b-col class="col-12 col-md-6 p-3" >
      <b-row  class="bluehalf">
        <b-col class="col-12 col-sm-8">
        <h5>Care4me and your privacy</h5>
        </b-col>
        <b-col class="col-12 col-sm-4">
        <b-btn block variant="light">Details</b-btn>
        </b-col>
      </b-row>
    </b-col>


  </b-row>  


</b-container>

<SiteFooter />

</div>
</template>

<style scoped>
.catimage{
  width:100%;
  height: auto;
}

.catrow{
  border:1px solid gray;
  border-radius: 10px;
  font-size: 1.3em;
  padding:5px;
  }

  
</style>

<script>
import axios from 'axios'
import SiteFooter from './SiteFooter.vue'
export default {
components: { SiteFooter },
  data(){
    return{
      catsel:0,
      catselect:{},
      cats:[]
      }
  },

mounted(){
          this.catselect.parentid = 999
          this.catselect.childid = 999
          this.catselect.parentname = 'All'
          this.catselect.childname = 'All'
          this.$store.commit('setcatsel',this.catselect)
          this.getCats()
      },

  methods:{

    getCats(){
             
                axios.get('https://care4medev.co.uk/api/getservicecats',{
                    params:{
                          buttons:1
                     }
                })
                .then((response) => {
                      this.cats = response.data.cats
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
       },

       selectCat(cat){
          let catsel = {parentid:cat.value,parentname:cat.text,childid:999,childname:''}
          this.$store.commit('setcatsel',catsel)
          this.$store.commit('setallresults',undefined)
          this.$router.push({ name: 'directory', params: {'home':true} }).catch(()=>{})
        },

        menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{}) 
            },     

  }            
}
</script>