<template>
<div>
    <b-row class="xbox mt-3 pb-xl-5">
    <b-col class="col-12">
        <b-btn variant="outline-info" size="sm" block @click.stop="toggleFilters()"><b-icon-sliders style="margin-right:10px" /> Filters</b-btn>
    </b-col>  
    </b-row>

    <b-row v-if="showfilter">
      <b-col class="col-12 col-md-2 col-lg-12 mt-3">
      <label>Your Location</label> 
      <b-form-input 
          type="search"
          v-model="area.postcode" 
          autocomplete = "off"
          placeholder="Postcode"
          @blur="setPostcode"
          @input="clearPCError"
          />
          <div v-if="postcodeerror" style="color:red">invalid postcode</div>
          <b-btn style="margin-top:5px" size="sm" variant="outline-info" @click.stop="clearArea()" v-if="range > 0">clear location</b-btn>   
      </b-col>
      
      <b-col class="col-12 col-md-2 col-lg-12 mt-3">
      <label>Distance</label>
      <b-form-select
              v-model="range"
              :options="ranges"
              @change="setRange"
          />
      </b-col>

       
      <b-col class="col-12 col-md-4 col-lg-12 mt-3">
            <label>Listing Group</label>
            <b-form-select
            v-model="psearch.id"
            :options="parents"
            @change="selectParent(psearch.id,1)"
            />
            <b-btn style="margin-top:5px" size="sm" variant="outline-info" @click.stop="clearCats()"  v-if="psearch.id !== 999">clear selection</b-btn>
        </b-col>

        <b-col class="col-12 col-md-4 col-lg-12 mt-3">
            <label>Listing Type</label>
            <b-form-select
            v-model="csearch.id"
            :options="childs"
            @change="selectChild(csearch.id)"
            />
        </b-col>

      
</b-row>

</div>
</template>

<style>

.box{
    background-color: #FFFFFF;
    margin-bottom:40px;
    padding:10px;
    border-radius: 5px;
    width:90%;
    margin-left:5%;
    -webkit-box-shadow: 5px 5px 8px -2px rgba(0,0,0,0.63); 
    box-shadow: 5px 5px 8px -2px rgba(0,0,0,0.63);
    color:rgba(0,0,0,0.7)
}
</style>

<script>
    import axios from 'axios'
    export default {
        data(){
		return {
            cats:[],
            parents:[],
            childs:[],
            psearch:{
                id:999,
                name:''
            },
            csearch:{
                id:999,
                name:''
            },
            catselect:{},
            catsel:0,
            postcode:'',
            postcodeerror:false,
            range:0,
            area:{},
            ranges:[
                {value:0,text:'Select'},
                {value:1,text:'1 mile'},
                {value:2,text:'2 miles'},
                {value:3,text:'3 miles'},
                {value:5,text:'5 miles'},
                {value:10,text:'10 miles'},
                {value:200,text:'Any'}
            ],
            loaded:false,
            showfilter:false
            };
        },
		
        mounted(){
            this.range = this.$store.getters.range
            this.area = this.$store.getters.area
            this.catsel = this.$store.getters.catsel
            if(this.catsel == null){
                this.psearch.id = 999
                this.psearch.name = 'All'
                this.csearch.id = 999
                this.csearch.name = 'All'
            } else {
            this.psearch.id = this.catsel.parentid
            this.psearch.name = this.catsel.parentname
            this.csearch.id = this.catsel.childid
            this.csearch.name = this.catsel.childname
            }
            this.getCats()
        },

      
		methods: {

            getCats(){ 

                axios.get('https://care4medev.co.uk/api/getservicecatsall',{
                        params:{}
                    })
                    .then((response) => {
                        this.cats = response.data
                        this.$store.commit('setcats',this.cats)
                        this.buildCatsSel()
                        })
                        .catch(err => {
                            this.$swal({
                                title: "System Error",
                                text: err,
                                icon: "error",
                                timer: 3000,
                                showCloseButton: false,
                                showConfirmButton: false
                                
                                })
                        });
            },

            buildCatsSel(){
                    this.parents = [{value:999,text:'All'}]
                    this.cats.forEach(element => {
                        this.parents.push({value:element.parent.service_cat_id,text:element.parent.service_cat_name})
                    });

                    this.childs = [{value:999,text:'All'}]
                    this.selectParent(this.psearch.id,0)
                    
                },

            selectParent(parentid,reset){
                
                if(reset == 1){this.csearch.id = 999}

                console.log('side set parent '+parentid)
                
                if(parentid == 999){ // all parent cats
                    this.childs = [{value:999,text:'All'}]
                    this.csearch.id = 999
                    this.csearch.name = 'All'
                    //this.searchid = 999
                } else {
                    this.childs = [{value:999,text:'All'}]
                    let data = this.cats.filter(cat => cat.parent.service_cat_id == parentid)[0]
                    data.childs.forEach(element => {
                        this.childs.push({value:element.service_cat_id,text:element.service_cat_name})
                    })
                    this.psearch.name = data.parent.service_cat_name
                    this.psearch.id = parentid
                    if(this.csearch.id == 999){
                    this.csearch.name = 'All'
                    this.csearch.id = 999
                    } else this.selectChild(this.csearch.id)

                   
                }
                this.saveCatSel()
            },

            selectChild(childid){
                console.log('side set child '+childid)
                
                if(childid == 999){
                    this.csearch.name = 'All'
                    this.csearch.id = 999
                } else {
                    let selchild = this.childs.filter(child => child.value == childid)[0]
                    console.log(selchild)
                    this.csearch.name = selchild.text
                    this.csearch.id = selchild.value
                }
                this.saveCatSel()
            },
            
            saveCatSel(){
                this.catselect.parentid = this.psearch.id
                this.catselect.parentname = this.psearch.name 
                this.catselect.childid = this.csearch.id
                this.catselect.childname = this.csearch.name
                this.$store.commit('setcatsel',this.catselect)
                console.log(this.catselect)
                this.$store.commit('setcatchange',!this.$store.getters.catchange)
            },    

            setPostcode(){

            if(this.$store.getters.postcode !== this.postcode){

                axios.get('https://care4medev.co.uk/api/postcodelookup',{
                        params:{
                            postcode:this.area.postcode
                            }
                    })
                    .then((response) => {
                        if(response.data.Error == 0){
                            this.area.lat = response.data.Position[0]
                            this.area.lng = response.data.Position[1]
                            this.$store.commit('setarea',this.area)
                            console.log(this.area)

                        } else this.postcodeerror = true
                        
                        })
                        .catch(err => {
                            this.$swal({
                                title: "System Error",
                                text: err,
                                icon: "error",
                                timer: 3000,
                                showCloseButton: false,
                                showConfirmButton: false
                                
                                })
                        });
            }
            },

            clearPCError(){
            this.postcodeerror = false
            },

            setRange(){
            if(!this.postcodeerror && this.area.lat != null ){
                this.$store.commit('setrange',this.range)
            }
            },

            toggleFilters(){
                this.showfilter = !this.showfilter
            },

            clearArea(){
            this.area.lat = null
            this.area.lng = null
            this.area.postcode = ''
            this.range = 0
            this.$store.commit('setarea',this.area) 
            this.$store.commit('setrange',this.range)
            },

            clearCats(){
                this.psearch.id = 999
                this.csearch.id = 999
                this.psearch.name = 'All'
                this.csearch.name = 'All'
                this.saveCatSel()

            },

          selCat(pcat,ccat){
                let catsel = {parentid:pcat.parent.service_cat_id,parentname:pcat.parent.service_cat_name,childid:ccat.service_cat_id,childname:ccat.service_cat_name}
                this.$store.commit('setcatsel',catsel)
            },

           
        }
    };
</script>