<template>
<div>
<b-container class="pagecont">

<b-row class="textbox" >
    <b-col class="col-12 col-sm-6 col-xl-4 offset-xl-2" ref="mainbox">
            <h4 v-if="listing.text == ''">Create new listing</h4>
             <h4 v-else>Manage listing</h4>
         </b-col>

         <b-col class="col-12 col-sm-6 col-xl-4 text-right">
               <h4 v-if="listing.text != ''">{{ listing.text }}</h4>
            <!-- <span :style="{backgroundColor:listing.pcolor}" class="publishstate">
                {{ listing.publishtext }} {{ listing.publishdate }}
            </span> -->
        </b-col>
        
</b-row> 

<b-row class="mt-5">
    <b-col class="col-12 col-md-8 offset-md-2 col-xl-8 offset-xl-2">         

<b-row class="mt-2 mb-3" >
        <b-col class="col-12 col-sm-3 mt-3 text-center">
            <b-btn block variant="outline-info" @click.stop="nextPage(1)">Details</b-btn>
        </b-col>
          
        <b-col class="col-12 col-sm-3 mt-3 text-center">  
            <b-btn block variant="outline-info" @click.stop="nextPage(2)">Location</b-btn>
        </b-col>
       
        <b-col class="col-12 col-sm-3 mt-3 text-center">    
            <b-btn block variant="outline-info" @click.stop="nextPage(3)">Categories</b-btn>
        </b-col>

         <b-col class="col-12 col-sm-3 mt-3 text-right">
            <b-btn  variant="outline-info" @click="previewListing()" style="margin-right:10px"></b-btn>
            <b-btn  variant="outline-danger" @click="previewListing()" ></b-btn>
        </b-col>
      
</b-row>  

<b-row>

    <b-col class="col-12 col-xl-8 offset-xl-2">

<b-container v-if="page==1" >

<b-row>
        <b-col class="col-12 col-sm-6 mt-3">
            <h3>Details</h3>
        </b-col>

        <b-col class="col-12 mt-3 mb-1">
            <label>Main Title</label>
            <b-form-input 
                v-model="listing.text"
                placeholder="eg: Putney Swimming Club "
                :rules="nameRules"
                prepend-icon="mdi-email-outline"
                required
                />

            </b-col>

        <b-col class="col-12 mt-3 mb-1">
            <label>Secondary Title</label>
            <b-form-input 
                v-model="listing.text2"
                placeholder="eg: Swimming lessons for age 18-80 "
                :rules="nameRules"
                prepend-icon="mdi-email-outline"
                required

                />

            
            </b-col>

        <b-col class="col-12 mt-3 mb-1">
            <label>Website (optional)</label>
            <b-form-input 
                v-model="listing.website"
                placeholder="eg: https://mywebsite.com "
                :rules="nameRules"
                prepend-icon="mdi-email-outline"
                />
          
            </b-col>

     
        <b-col class="col-12 mt-3 mb-1">
            <label>Contact Email</label>
            <b-form-input 
                v-model="listing.email"
                placeholder=""
                :rules="emailRules"
                prepend-icon="mdi-email-outline"
                />
          
        </b-col>

        <b-col class="col-12 mt-3"><h5>Listing Description</h5></b-col>
        <b-col class="col-12 mt-3 mb-1">
                <mc-wysiwyg v-model="listing.page_desc" :hide="hide"></mc-wysiwyg>
        </b-col>
</b-row>



<b-row class="mt-3">
        <b-col class="col-12 mt-3">
        <h5>Logo Image (optional)</h5>
    </b-col>
        
        <b-col class="col-12  mt-3" v-if="logoimage == ''">
            <ImageUploader 
                v-on:upload="LogofileUploaded"
                />
        </b-col>

        <b-col class="col-12 col-md-4 mt-3" v-else>
                <b-row>
                    <b-col class="col-12">
                    <img :src="'https://care4medev.co.uk/images/logos/'+logoimage" style="height:200px" />
                    </b-col>
                    <b-col class="col-12 mt-3">
                    <b-btn class="mt-1" size="sm" variant="outline-info" @click="resetLogo()">Change logo</b-btn>
                    </b-col>
                </b-row>    
       
         </b-col>        

</b-row>  

</b-container>

</b-col>

</b-row>


<b-container v-if="page==2" >

<b-row class="mt-3" v-if="listing.showmap == 1">
    <b-col class="col-8 mt-3">
        <h3>Location</h3>
    </b-col>

    <b-col class="col-4">
        <b-checkbox
            v-model="listing.showmap"
            inline
            value=1
            unchecked-value=0
            size="lg"
        />
        <label>Show map for listing</label>
    </b-col>  

     <b-col class="col-12 col-sm-6 mt-3" ref="mainbox">
        <b-row>
            <b-col class="col-12 mt-3 mb-1">
                <b-form-input 
                label="Building / House name"
                v-model="listing.add1"
                placeholder=""
                :rules="nameRules"
                prepend-icon="mdi-email-outline"
                required
                />
            </b-col>

            <b-col class="col-12 mt-3 mb-1">
                <b-form-input 
                label="Street"
                v-model="listing.add2"
                placeholder=""
                :rules="nameRules"
                prepend-icon="mdi-email-outline"
                required
                />
            </b-col>

            <b-col class="col-12 mt-3 mb-1">
                <b-form-input 
                label="Area"
                v-model="listing.add2"
                placeholder=""
                :rules="nameRules"
                prepend-icon="mdi-email-outline"
                required
                />
              
            </b-col>

         <b-col class="col-12 col-sm-6 mt-3 mb-1">
            <b-form-input 
                label="Postcode"
                v-model="listing.postcode"
                placeholder=""
                :rules="nameRules"
                prepend-icon="mdi-email-outline"
                required
                />
            </b-col>

            
            <b-col class="col-12 col-sm-6 mt-3 mb-1">
                <b-form-input 
                label="Phone"
                v-model="listing.tel"
                placeholder=""
                :rules="nameRules"
                prepend-icon="mdi-email-outline"
                />

            </b-col>

        </b-row>
     </b-col>

    <b-col class="col-12 col-sm-6 mt-3">
        <b-row>
           <b-col class="col-12">

            <div style="width:100%" v-if="display"> 

            <v-map
            ref="myMap"
            :center="[listing.gm_lat,listing.gm_long]"
            :zoom="currentZoom"
            :options="mapOptions"
            :style="{height: mapheight + 'px', width: mapwidth + 'px'}"
            :noBlockingAnimations="blockanimation"
            >
            <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            <v-marker :lat-lng="[listing.gm_lat,listing.gm_long]" v-if="listing.postcode !== ''">
              <v-licon :icon-anchor="staticAnchor">
                <img :src="mapmarker" style="height:30px;width:auto">
                <div class="headline" v-if="listing.text != ''"><span translate="no">{{ listing.text }}</span></div>
              </v-licon>
            </v-marker> 

            </v-map>

            </div>

           </b-col>

        </b-row>       

        </b-col> 
 
</b-row>

<b-row class="mt-3" v-else>
    <b-col class="col-8 mt-3">
        <h5>Location not shown for listing</h5>
    </b-col>
     <b-col class="col-4">
        <b-checkbox
            v-model="listing.showmap"
            inline
            value=1
            unchecked-value=0
            size="lg"
        />
        <label>Show map for listing</label>
    </b-col>  
</b-row>   

</b-container>



<b-row>
    <b-col class="col-12 col-md-10 offset-md-1">

<b-container v-if="page==3" >

<b-row class="mt-3">
    <b-col class="col-8 mt-3">
        <h3>Listing Categories</h3>
    </b-col>
</b-row> 

<b-row class="mt-1">
    <b-col class="col-12 col-sm-6" v-for="cat in cats" :key="cat.value">

        <b-row class="mt-1"> 

            <b-col class="col-1 mt-3">
                <b-checkbox
                    v-model="cat.selected"
                    inline
                    value=1
                    unchecked-value=0
                    size="lg"
                />
            </b-col>

            <b-col class="col-8 mt-3">
                {{ cat.text }}
            </b-col>
        </b-row> 

    </b-col>
</b-row>        

</b-container>

</b-col>
</b-row>

    <b-row class="mt-5 mb-5">
       
        <b-col class="col-12 col-sm-4 offset-sm-2">
            <b-btn class="mt-1"  size="lg" block variant="success" @click="validateAll()">
            <span v-if="listing.value == 0">Submit listing</span>
            <span v-else>Update listing</span>
            </b-btn>
        </b-col>

        <b-col class="col-12 col-sm-4 offset-sm-1">
            <b-btn class="mt-1"  size="lg" block variant="warning" @click="goBack()">Back</b-btn>
        </b-col>
        
    </b-row>    

</b-col>
</b-row>

<b-modal ref="preview-listing" size="md" hide-header  hide-footer centered>
    <ProviderPopup :providerpreview="listing" :providerid=0 />
</b-modal>  

</b-container>

</div>
</template>

<style scoped>
.headline{
  background-color:rgba(255,255,255,0.7);
  position: absolute;
  padding: 5px;
  min-width: 100px;
  border-radius: 10px;
}

.stageIcon{
  
    border:2px solid green;
    height: 100px;
    width:120px;
    border-radius:40px;
    padding-top:20px;
} 

.stageLine{
    float:left;
    width:100%;
    border:2px solid blue;
    margin-top:50px;
}

.icon2{
    font-size:2em;
}
</style>

<script>
import ProviderPopup from'./ProviderPopup.vue'
import ImageUploader from './ImageUploader.vue'
import { McWysiwyg } from '@mycure/vue-wysiwyg'
import axios from 'axios'
import "leaflet/dist/leaflet.css"
import * as Vue2Leaflet from 'vue2-leaflet'
import { latLng } from "leaflet"
import { Icon } from 'leaflet'
import mapmarker from '@/assets/images/mapmarker.png'
import { Validator } from 'vee-validate'    

delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
  
  export default {
    components: {
        'v-map': Vue2Leaflet.LMap,
        'v-marker': Vue2Leaflet.LMarker,   
        'v-tilelayer': Vue2Leaflet.LTileLayer,
        'v-licon': Vue2Leaflet.LIcon,
        McWysiwyg,
        ProviderPopup,
        ImageUploader 
    },
  data(){
    return{
            id:0,
            user:{},
            provider:{},
            listing:{
                value:0,
                text:'',
                text2:'',
                desc:'',
                add1:'',
                add2:'',
                add3:'',
                postcode:'',
                email:'',
                gm_lat:51.45573031047103,
                gm_long: -0.19369599774292554,
                showmap:1,
                cats:[]
            },
            cats:[],
            logoimage:'',
            mapmarker,   
            lat:0,
            long:0,
            staticAnchor: [0,0],
            blockanimation:true,
            currentZoom:14,
            satlayergroup: {},
            currentCenter: latLng(51.45573031047103, -0.19369599774292554),
            setbounds:false,
            mapOptions: {
                zoomSnap: 0.5
                },
          
            mapwidth:300,
            mapheight:300,
            display:false,
            postcode:'',
            modaltitle:'',
            hide: {
                strikethrough: true,
                table: true,
                url: true
            },
            page:1,
            pcerror:false,
            nameRules: [
                v => !!v || 'Username is required',
            ],
        emailRules: [
                v => !!v || 'E-mail is required',
               // v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ], 
          }
  },


  created(){ 
        window.scrollTo(0, 0);
        this.id = this.$route.params.id
        this.token = this.$store.getters.token
        this.waitfetchServiceCats().then(() => {
            if(this.id != 0){this.fetchListing()}
            else this.fetchProvider()
        })    

        
  },

  mounted(){
        this.mapwidth = this.$refs.mainbox.clientWidth -50
        Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   
  },

  
  methods:{


   
      fetchListing(){
                axios.get('/api/providerlistings',{ 
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        id:this.id,
                    }
                })
				.then((response) => {
                    if(response == 999){
                        console.log(response.error)
                    } else {
                            this.listing = response.data.Pages[0]
                            //console.log(this.listing)
                            if(this.listing.page_logo != '' || this.listing.page_logo != null ){
                                this.logoimage = this.listing.page_logo
                                this.postcode = this.listing.postcode
                                this.listing.showmap = 1
                                this.display =true
                                }

                            let pagecats = response.data.Cats
                           
                            pagecats.forEach(element => {
                                let cat  = this.cats.filter(cat => cat.value == element.id)
                                if(cat !== null){
                                    cat[0].selected = 1
                                    this.listing.cats.push(cat[0])
                                    //console.log(this.listing)
                                }
                                
                            });

                         
                            }  
                    });
           }, 

    fetchProvider(){
                axios.get('/api/provider',{ 
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        id:this.$store.getters.user.provider,
                        //all:1
                    }
                })
				.then((response) => {
                    if(response == 999){
                        console.log(response.error)
                    } else {
                            let provider = response.data[0]
                            console.log(provider)
                            if(provider !== undefined){
                            this.listing.email = provider.provider_email
                            this.listing.add1 = provider.provider_add1
                            this.listing.add2 = provider.provider_add2
                            this.listing.add2 = provider.provider_add3
                            this.listing.postcode = provider.provider_postcode
                            } else console.log('No data') 
                            this.display = true
                            }
                    });
           },

    async waitfetchServiceCats(){
                await this.fetchServiceCats()
            },       

    async fetchServiceCats(){
                return new Promise((resolve) => {

                axios.get('/api/getservicecats',{ 
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        parent:1
                        //all:1
                    }
                })
				.then((response) => {
                    if(response == 999){
                        console.log(response.error)
                    } else {
                            this.cats = response.data.cats
                            //console.log(this.cats)
                            resolve()
                            }
                    });
                }) // end resolve     
           },
           
     updateListing(){

             axios({
                    method: 'post',    
                    headers: {"Authorization" : "Bearer " + this.token},
                    url: '/api/updatelisting',
                    data: {
                        data:this.listing,
                        data2:this.cats
                        }
                    })
                .then((response)=> {

                if(response.data.code == 999){
                        alert('error')
                    } else {
                    
                        console.log(response)
                        
                    }
            
                });	
     },      

     getLatLong(){

        if(this.listing.postcode == this.postcode && this.listing.postcode  == ''){
            console.log('no display')
            return}

            let apiKey = "ak_lm91i6dgahwp9TXWjQjvbIlcUWMUD"

                fetch(`https://api.ideal-postcodes.co.uk/v1/postcodes/${this.listing.postcode}?api_key=${apiKey}`)
                .then(response => response.json())
                .then(data => {
                    if(data.message == 'Success'){
                    this.listing.gm_lat = data.result[0].latitude
                    this.listing.gm_long = data.result[0].longitude;
                    this.currentCenter = latLng(this.listing.gm_lat, this.listing.gm_long)
                    this.pcerror = false
                    } else  this.pcerror = true
                })
                .catch(error => {
                    console.error("Error:", error);
                });    
    },
    
    LogofileUploaded(file){
                this.logoimage = file
    },
            
    resetLogo(){
            this.logoimage = '' 
    },

    nextPage(page){
        if(page != null){
            this.page = page
        } else this.page++
    },

    prevPage(){
        this.page--
    },
      
    validateAll(){
            this.$validator.validateAll().then(result => {
                   if(result){
                       this.updateListing()
                       } else alert('error')
               })
    },

    validateField(field){
              this.$validator.validate(field)
              if(field == 'listing.postcode'){
                this.pcerror = false
              }
    },

    previewListing(){
            this.$refs['preview-listing'].show()
    },

    goBack(){
          this.$router.push({ name: 'mylistings', params: {} }).catch(()=>{})
    }

    
  }            
}
</script>