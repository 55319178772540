<template>
    <div>
    
        <b-row class="mt-0" v-if="searchtext !== ''">
           
            <b-col class="col-12 col-md-6 mt-1" >
                <h5>Showing search results for:</h5> 
            </b-col>

            <b-col class="col-12 col-md-6 text-md-right mt-1" >
                 <h5><span translate="no">'{{ searchtext }}'</span>
                <b-icon-x-circle  class="clearicon" @click.stop="clearSearch()"/>
                </h5>
            </b-col>
        </b-row>
        
        <b-row>

            <b-col class="col-12 mt-1">
                <b-row>
                
                <b-col class="col-12">
                    <div class="catchoice" v-if="psearch.id != 999">{{  psearch.name }} <b-icon-x-circle  class="clearicon" @click.stop="clearParentSearch()"/></div>
                    <div class="catchoice" v-if="csearch.id != 999"> {{  csearch.name }} <b-icon-x-circle  class="clearicon" @click.stop="clearChildSearch()"/></div>
                </b-col>

                </b-row>
                
            </b-col>
           
        </b-row>
    
        <b-row class="mt-3 mb-3">
            <b-col class="col-12 col-md-8">
                <b-button variant="outline-info" v-bind:class="[{ buttonsel: pagenum == page+1 }, 'buttonnosel']" size="sm" v-for="pagenum in pages" :key="pagenum.id" @click="gotoPage(pagenum)">{{ pagenum }}</b-button>
            </b-col>

            <b-col class="col-12 col-md-4 text-md-right mt-1" v-if="resultcount > 0">   
                <p v-if="resultcount > (limit+1)">{{  (page * limit+1) }} - {{  (page + 1) * limit }} of {{ resultcount }} results</p>
                <p v-else>{{  (page * limit+1) }} - {{  resultcount }} of {{ resultcount }} results</p>
            </b-col>
    
            
        </b-row>  
                
        <b-row class="mt-1" v-if="resultslist.length == 0 && loading == false"> 
            <b-col class="col-12 p-3 border text-center">
                    <h5>
                    No results found for "{{ searchtext }}" 
                    <span v-if="psearch.id !== 999">in {{ psearch.name }}</span>
                    <span v-if="csearch.id !== 999"> | {{ csearch.name }}</span>
                    </h5>
            </b-col>
        </b-row>  
        
        <b-row >
            <b-col class="col-12 xlistingbox" @click.stop="showListingPage(listing)" v-for="listing in resultslist" :key="listing.value">
                <div class="listingbox">
                <b-row>
                    <b-col class="col-12 col-sm-8">
                    <h4><span translate="no">{{ listing.sp_title_1 }}</span></h4>
                    <h5>{{ listing.sp_title_2 }}</h5>
                    <p>{{ listing.sp_desc }}</p>
                    </b-col>
                    <b-col class="col-12 col-sm-4 text-center text-sm-right">
                        <img v-if="listing.logo_size[0] !== 0 && listing.logo_size[1] >= listing.logo_size[0]" class="provider_logo" :src="`https://care4medev.co.uk/images/logos/${listing.sp_logo}` ">
                        <img v-if="listing.logo_size[0] !== 0 && listing.logo_size[0] > listing.logo_size[1]" class="provider_logo_large" :src="`https://care4medev.co.uk/images/logos/${listing.sp_logo}` ">
                   </b-col>
                </b-row>
                <span class="dist" v-if="listing.sp_distance != 999 && range > 0">{{  listing.sp_distance }} miles</span>
                </div>
             </b-col>
        </b-row>        
    
    
    <div class="processing" v-if="loading"><img :src="spin" style="margin-left:-35px,width:100px;height:100px"></div>         
    
    
   
    </div> 
    </template>
    
    <style scoped>
    .processing{
        position: fixed;
        top:50%;
        left:50%;
        z-index:999;
    }
    
   
    
    .dist{
        font-size:1em;
        font-weight: 100;
    }
    </style>
    
    <script>
        import axios from 'axios'
        import { mapState } from 'vuex'
        import spin from '@/assets/images/process.gif' 
        export default {
            data(){
            return {
                resultslist:[],
                resultcount:0,
                cats:[],
                parents:[],
                childs:[],
                searchid:999,
                searchtext:'',
                catselect:{},
                spin,
                loading:false,
                pages:[],
                page:0,
                limit:100,
                showsearchcats:false,
                psearch:{
                    id:0,
                    name:''
                },
                csearch:{
                    id:0,
                    name:''
                },
            };
            },
            
            computed: mapState(['results','range','catchange','stext']),
    
            watch: {

                stext(){
                    this.searchtext = this.stext
                    if(this.results == 0){
                        console.log('results by text')
                        this.resultslist = []
                        this.resultcount = 0
                        this.pages = 0
                    }
                },

                results(){  // Result array from search bar page - listing page / text 
                    if(this.results !== undefined){
                        if(this.results.length > 0){
                        console.log('results by list')
                        this.showsearchcats = false
                        this.catselect.parentid = 999
                        this.catselect.parentname = 'All'
                        this.catselect.childid = 999
                        this.$store.commit('setcatsel',this.catselect)
                        this.getResultsByList()
                        }
                    } 
                },

                range(){  // use postcode and range 
                    console.log('results by range and text')
                    this.catsel = this.$store.getters.catsel
                    this.page = 0
                    if(this.searchtext != ''){
                    this.getResultsbyText()
                    } else {
                        if(this.catsel.childid == 999){this.searchid = this.catsel.parentid} else this.searchid = this.catsel.childid
                        this.searchtext = ''
                        this.getResultsbyCat()
                        }
                },

                catchange(){  // use side cat 
                    console.log('cat change results by side')
                    this.setCatSel()
                },
           
            },
    
            mounted(){

                //this.$store.commit('setcatsel',null)
               
                // this.searchtext = this.$store.getters.stext
                
                if(this.$store.getters.catsel != null){
                    this.catselect = this.$store.getters.catsel    
                    this.psearch.id = this.catselect.parentid
                    this.psearch.name = this.catselect.parentname
                    this.csearch.id = this.catselect.childid
                    this.csearch.name = this.catselect.childname
                    if(this.csearch.id > 0){this.searchid = this.csearch.id } else this.search.id = this.psearch.id
                    this.showsearchcats = true
                    
                }
             
                if(this.$store.getters.allresults != undefined){
                    this.resultslist = this.$store.getters.allresults
                    this.resultcount = this.$store.getters.allresultscount
                    this.page = this.$store.getters.page
                    this.searchtext = this.$store.getters.stext
                    this.setPages()
                } else this.setCatSel()

                window.scrollTo(0,0)
               
            },
    
            methods: {

                setCatSel(){
                    this.catsel = this.$store.getters.catsel
                    console.log('results by side')
                    this.psearch.id = this.catsel.parentid
                    this.psearch.name = this.catsel.parentname
                    this.csearch.id = this.catsel.childid
                    this.csearch.name = this.catsel.childname
                    if(this.catsel.childid == 999){
                        this.searchid = this.catsel.parentid
                        this.csearch.name = 'All'
                    } else this.searchid = this.catsel.childid
                    
                    if(this.psearch.id !== 999){this.showsearchcats = true} else this.showsearchcats = false
                    
                    this.getResultsbyCat()
                },    
            
                getResultsbyCat(){
                    
                    this.loading = true
    
                    axios.get('https://care4medev.co.uk/api/getprovidersbycat',{
                        params:{
                            id:this.searchid,
                            stext:this.searchtext,
                            page:this.page,
                            limit:this.limit,
                            area:this.$store.getters.area,
                            range:this.$store.getters.range
                            }
                    })
                    .then((response) => {
                       
                        this.resultslist = response.data.Results
                        this.resultcount = response.data.Count
                        this.setPages()
                        this.loading = false
                        this.showsearchcats = true
                        this.saveResults(response)
                         })
                        .catch(err => {
                            this.$swal({
                                title: "System Error",
                                text: err,
                                icon: "error",
                                timer: 3000,
                                showCloseButton: false,
                                showConfirmButton: false
                                
                                })
                        });
               },
    
                 getResultsbyText(){
                    console.log('get results by text')
                    this.loading = true
    
                   // axios.get('https://care4medev.co.uk/api/getservicecatstext',{
                        axios.get('https://care4medev.co.uk/api/getprovidersbycat',{
                        
                        params:{
                            stext:this.searchtext,
                            area:this.$store.getters.area,
                            range:this.$store.getters.range
                            }
                    })
                    .then((response) => {
                        this.resultslist = response.data.Results
                        this.resultcount = response.data.Count
                        this.searchtext = this.$store.getters.stext
                        this.setPages()
                        this.loading = false
                        this.saveResults(response)
                         })
                        .catch(err => {
                            this.$swal({
                                title: "System Error",
                                text: err,
                                icon: "error",
                                timer: 3000,
                                showCloseButton: false,
                                showConfirmButton: false
                                
                                })
                        });
      
               },

               getResultsByList(){  // get results based on results store array 

                    let results = this.results.map(item => item.page); // Map each object to its name
                    this.loading = true
    
                    axios.get('https://care4medev.co.uk/api/getprovidersbylist',{
                        params:{
                            list:results,
                            area:this.$store.getters.area,
                            range:this.$store.getters.range
                            }
                    })
                    .then((response) => {
                        
                        this.resultslist = response.data.Results
                        this.resultcount = response.data.Count
                        this.showsearchcats = false
                        this.searchtext = this.$store.getters.stext
                        this.setPages()
                        this.loading = false
                        this.saveResults(response)
                        })
                        .catch(err => {
                            this.$swal({
                                title: "System Error",
                                text: err,
                                icon: "error",
                                timer: 3000,
                                showCloseButton: false,
                                showConfirmButton: false
                                
                                })
                        });
      
               },
    
               saveResults(response){
                this.$store.commit('setallresults',response.data.Results)
                this.$store.commit('setallresultscount',response.data.Count)
                this.$store.commit('setpage',this.page)
                this.$store.commit('setmappoints',response.data.ProvidersMap)
               },
    
               setPages(){
                    this.pages = []
                    let loop = 0
                    while(loop < this.resultcount/this.limit){
                        this.pages.push(loop + 1)
                        loop++
                    }
                },
    
                clearSearch(){
                    this.searchtext = ''
                    this.$store.commit('setstext','')
                    this.page = 0
                    if(this.csearch.id == 999){this.searchid = this.psearch.id} else this.searchid = this.csearch.id
                    this.updateCatSel()
                },

                clearParentSearch(){
                    this.psearch.id = 999
                    this.csearch.id = 999
                    this.csearch.name = ''
                    this.psearch.name = 'All'
                    this.searchid = 999
                    this.updateCatSel()
                },

                clearChildSearch(){
                    this.csearch.id = 999
                    this.csearch.name = ''
                    this.searchid = this.psearch.id
                    this.updateCatSel()
                },

                updateCatSel(){
                    this.catselect.parentid = this.psearch.id
                    this.catselect.parentname = this.psearch.name 
                    this.catselect.childid = this.csearch.id
                    this.catselect.childname = this.csearch.name
                    this.$store.commit('setcatsel',this.catselect)
                    this.$emit('catupdate')
                    this.getResultsbyCat()
                },
               
                gotoPage(page){
                    this.page = page - 1
                    window.scrollTo(0,0)
                    this.getResultsbyCat()
                    //this.getResults()
                },
    
                showListingPage(event){
                    this.$router.push({ name: 'pagelisting', params: {id:event.id} }).catch(()=>{}) 
                },
    
            }
        
    };
    
    </script> 