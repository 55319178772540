<template>
<div>

<b-container class="pagecont">
<navbar-main  />
<router-view  />

</b-container>
  
</div>
</template> 

<script>
    import logo from '@/assets/images/care4me_logo_l.jpeg'
    import logo2 from '@/assets/images/care4me_logo_r.jpeg'
	export default { 
  data(){
    return{
      logo,
      logo2
    }
    },
  
    mounted(){
       // setTimeout(() =>  this.googleTranslateElementInit(), 1000)
      
    },
 
    methods : {

     
    googleTranslateElementInit() {
        window.google.translate.TranslateElement(
          { 
            pageLanguage: "en",
            layout: "google.translate.TranslateElement.InlineLayout.HORIZONTAL"
          },
          "google_translate_element"
        );
      },
       
    }


}
</script>