import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

const store = new Vuex.Store({
        
    plugins: [createPersistedState({
        paths: ['token','user','loggedin','mobile','area','range','stext','mapshow','allresults','allresultscount','page','results','mappoints','catsel','cats'],
      })],
          
           state: {
            user:{},
            token:'',
            loggedin:false,
            mobile:false,
            results:[],
            allresults:[],
            allresultscount:0,
            page:0,
            providersel:0,
            searchtext:'',
            catchange:false,
            catsel:{
              parentid:999,
              parentname:'All',
              childid:999,
              childname:'All',
            },
            cats:[],
            mappoints:[],
            range:0,
            mapshow:true,
            area:{
              text:'Select',
              lat:51.4567,
              lng:-0.1910,
              postcode:''
            },
          
            stext:'',
            tab:0,
          },
          mutations: {

              setcatchange(state, catchange) {
                     state.catchange = catchange
              },
             
              setallresults(state, allresults) {
                     state.allresults = allresults
              },

              setallresultscount(state, allresultscount) {
                     state.allresultscount = allresultscount
              },

              setmobile(state, mobile) {
                     state.mobile = mobile
              },


              setpage(state, page) {
                     state.page = page
              },

              setmapshow(state,mapshow) {
                     state.mapshow = mapshow
              },

              setresults(state, results) {
                     state.results = results
              },

              settoken(state, token) {
                     state.token = token
              },

              setuser(state, user) {
                     state.user = user
              },

              setloggedin(state,loggedin) {
                     state.loggedin = loggedin
              },

              setcatsel(state, catsel) {
                     state.catsel = catsel
              },

              setprovidersel(state, providersel) {
                state.providersel = providersel
              },

              setstext(state, stext) {
                     state.stext = stext
              },

              setmappoints(state, mappoints) {
                     state.mappoints = mappoints
              },

              setrange(state, range){
                     state.range = range    
              },

              setpostcode(state, postcode){
                     state.postcode = postcode    
              },

              setarea(state, area){
               state.area = area    
              },

              setcats(state, cats){
              state.cats = cats   
              },
         },
          getters: {

             catchange (state) {
                     return state.catchange
              },

              allresults (state) {
                     return state.allresults
              },

              allresultscount (state) {
                     return state.allresultscount
              },

              page (state) {
                     return state.page
              },

              mobile (state) {
                     return state.mobile
              },

              mapshow (state) {
                     return state.mapshow
              },

              results (state) {
                     return state.results
              },

              token (state) {
                     return state.token
              },

              user (state) {
                     return state.user
              },

              loggedin (state) {
                     return state.loggedin
              },

              pages (state) {
                     return state.pages
             },

              providersel (state) {
                return state.providersel
              },

              stext (state) {
              return state.stext
              },

              searchtext (state) {
              return state.searchtext
              },

              catsel (state) {
              return state.catsel
              },

              mappoints (state) {
                return state.mappoints
              },

              range (state) {
              return state.range
              },

              postcode (state) {
                     return state.postcode
                     },

              area (state) {
              return state.area
              },

              cats (state) {
              return state.cats
              },       
               
          }
      })
      
export default store