<template>
<div >  

  <b-container style="background-color:#deedf0;max-width:100%">
 
  <b-row class="pt-3">
    <b-col class="col-12 col-md-3">
      <img ref="brandingLogo" :src="logo" style="height:50px;padding:1px;">
    </b-col>
    
    <b-col class="col-12 col-md-6 mt-2">
      <h6>This site is managed by 7 Clouds Ltd.<br/>Visitors who use this website and rely on any information do so at their own risk.
Please read our disclaimer.</h6>
    </b-col>

    <b-col class="col-12 col-md-3 text-right">
      <img ref="brandingLogo" :src="logo2" style="height:60px;padding:1px;">
    </b-col>

  </b-row>
  
  
</b-container>
</div>
</template> 
  
  <script>
  import logo from '@/assets/images/care4me_logo_l.jpeg'
  import logo2 from '@/assets/images/7cloudsLogo.jpeg'
  
  export default { 
    data(){
      return{
        logo,
        logo2,
      }
    },
  
    mounted(){
         
     },
          
    methods: {
  
    menuLink(link){
        this.$router.push({ name: link, params: {} }).catch(()=>{})
    },
  
      } 
  }
  </script>