<template>
<div >
  <v-container style="background-color:white">
      <v-row >
                <v-col class="col-12 text-right" id="mapbox" >
                    <img v-if="listing.sp_logo !== ''" class="provider_logo" :src="`https://care4medev.co.uk/storage/providerimages/${listing.sp_logo}` ">
                </v-col>

                <v-col class="col-12">
                <h4><span translate="no">{{ listing.sp_title_1 }}</span></h4>
                <h5>{{ listing.sp_title_1 }}</h5>
                <div v-html="listing.sp_desc" />
                </v-col>
                
        </v-row>

          <v-row class="mt-3">
            <v-col class="col-12" v-if="listing.sp_tel !== ''">{{ listing.sp_tel }}</v-col>
            <v-col class="col-12" v-if="listing.sp_email !== ' '">{{ listing.sp_email }}</v-col>
            <v-col class="col-12" v-if="listing.sp_website !== ''">{{ listing.sp_website }}</v-col>
        </v-row>   

        <v-row class="mt-3">
            <v-col class="col-12">
                {{ listing.sp_add1 }}<br/>
                {{ listing.sp_add2 }}<br/>
                {{ listing.sp_add3 }}<br/>
                {{ listing.sp_postcode }}<br/>
            </v-col>
        </v-row>

        <!-- <v-row class="mt-3">
          <v-col class="col-12">
            <div v-for="cat in listing.cats" :key="cat.value" style="background-color:lightblue;float:left;padding:5px;border-radius:5px">
                {{ cat.text }}
            </div>
          </v-col>
        </v-row>   -->

        <v-row class="mt-3">
        <v-col class="col-12 m-0 p-0" >

            <div style="width:100%"> 

            <v-map
            v-if="mapshow"
            ref="myMap"
            :center="currentCenter"
            :zoom="currentZoom"
            :options="mapOptions"
            :style="{height: mapheight + 'px', width: mapwidth + 'px'}"
            :noBlockingAnimations="blockanimation"
           
            >
          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            <v-marker
               
                :lat-lng="[lat,long]"
               
               >
              <v-licon      
                                :icon-anchor="staticAnchor"
                                >
                                <img :src="mapmarker" style="height:30px;width:auto">
                                <div class="headline"><span translate="no">{{ listing.sp_title_1 }}</span></div>
                           
              </v-licon>

             </v-marker> 
          </v-map>

            </div>

        </v-col>
       
</v-row>
</v-container>     
</div> 
</template>

<style scoped>
.headline{
  background-color:rgba(255,255,255,0.7);
  position: absolute;
  padding: 5px;
  min-width: 100px;
  border-radius: 10px;
  font-size:1em !important;
  line-height: 1em !important;
}
</style>

<script>
   import axios from 'axios'
   import "leaflet/dist/leaflet.css"
   import * as Vue2Leaflet from 'vue2-leaflet'
   import { latLng } from "leaflet"
   import { Icon } from 'leaflet'
   import mapmarker from '@/assets/images/mapmarker.png'

    delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
  
  export default {
    components: {
      
      'v-map': Vue2Leaflet.LMap,
      'v-marker': Vue2Leaflet.LMarker,   
      'v-tilelayer': Vue2Leaflet.LTileLayer,
     // 'v-popup': Vue2Leaflet.LPopup,
      'v-licon': Vue2Leaflet.LIcon
    },
    //props:['listing','providerid'],    
		data(){
        return {
                mapmarker, 
                providersel:0, 
                provider:{},
                lat:0,
                long:0,
                staticAnchor: [0,0],
                blockanimation:true,
                currentZoom:12,
                satlayergroup: {},
                currentCenter: latLng(51.45573031047103, -0.19369599774292554),
                setbounds:false,
                mapOptions: {
                    zoomSnap: 0.5
                  },
                display:false,  
                mapwidth:300,
                mapheight:300,
                mapshow:false  
           };
        },

       
        mounted(){

          this.providersel = this.$route.params.id
          alert(this.providersel)
     
            
        },

        methods: {

          fetchProvider(){
                    
                    axios.get('https://care4medev.co.uk/api/getprovider',{
                        params:{
                            id:this.providersel
                         }
                    })
            .then((response) => {
                       
                        this.listing = response.data.Providers[0]
                        
                        if(this.listing.gm_lat){
                          this.showMap()
                        }
                        
                        })
                        .catch(err => {
                            this.$swal({
                                title: "System Error",
                                text: err,
                                icon: "error",
                                timer: 3000,
                                showCloseButton: false,
                                showConfirmButton: false
                                
                                })
                        });
      
          },  
         

          showMap(){

            setTimeout(()=>{
              this.mapwidth = document.getElementById("mapbox").clientWidth
              this.provider = this.providerpreview
              this.lat = this.listing.sp_gm_lat
              this.long = this.listing.sp_gm_long
              if(this.lat > 0){
              this.currentCenter = latLng(this.lat,this.long)
              this.mapshow = true
              } else this.mapshow = false
              this.display = true
              console.log(this.listing)

             }, 400)

           },

    }
};

</script> 