<template>  
<div>

   <b-row class="textbox">
    <b-col class="col-12 col-xl-6 offset-xl-2 mt-2">
          <h4>Volunteer Positions</h4>
         </b-col>
   </b-row>  

   <b-container class="pagecont">

   <b-row class="mt-5">
    <b-col class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3">
        <b-row>
            <b-col class="col-12 text-right">
            <h6>{{ positions.length}} Results</h6>
            </b-col> 
        </b-row>

   <b-row class="pb-3" v-for="position in positions" :key="position.value">
    <b-col class="col-12 listingbox">
        <b-row>
      
        <b-col class="col-12 mt-3">
            <h4>{{ position.text }}</h4>
             <h4>{{ position.posted }}</h4>
        </b-col>

        <b-col class="col-12 mt-3 text-right">
           
            <img v-if="position.provider.provider_logo !== null" class="provider_logo" :src="`https://care4medev.co.uk/images/logos/${position.provider.provider_logo}` ">
        </b-col>

        
        <b-col class="col-12 mt-3">
            <h6>{{ position.desc }}</h6>
        </b-col>

        <b-col class="col-12 mt-3">
            <b-btn variant="success" @click.stop="positionPopup(position)">View details</b-btn>
        </b-col>

    </b-row>

    </b-col>
        
   </b-row>

</b-col>
</b-row>

<b-modal v-model="showposition" width="700" style="background-color:white !important">
    <PositionPopup :position="positionsel" />
</b-modal> 

</b-container>

</div>
</template>


<script>
import axios from 'axios'
import PositionPopup from './PositionPopup.vue'
export default {
  components: { 
  PositionPopup
  },        
  data(){
    return{
            positions:[],
            showposition:false,
            positionsel:{},
            modaltitle:'-'
            
          }
  },


  created(){ 
        this.fetchPositions()
       
  },
  
  methods:{

      fetchPositions(){
                axios.get('/api/getvolpositions',{ 
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        //id:this.$store.getters.user.provider,
                        //all:1
                    }
                })
				.then((response) => {
                    if(response == 999){
                        console.log(response.error)
                    } else {
                            this.positions = response.data.Volunteers
                            console.log(this.positions)
                         
                            }
                    });
           },

            positionPopup(event){
                this.positionsel = event
                this.showposition = true
            },

    
  }            
}
</script>