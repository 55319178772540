<template>
<div id="maincont">

<b-container id="sidemenu" :class="{ 'menu-open': isOpen }">
  <b-row class="mt-5">
    <b-col class="col-12">
  <ul>
    <li @click.stop="menuLink('home')">Home</li>
    <li @click.stop="menuLink('directory')">Directory</li>
    <li @click.stop="menuLink('volunteerpositions')">Volunteering</li>
    <li @click.stop="menuLink('helpsupport')">Help & Support</li>
    <li v-if="userloggedin" @click.stop="menuLink('mycare4me')">Account</li>
    <li v-if="userloggedin" @click.stop="menuLink('mylisitings')">Listings</li>
    <li v-if="userloggedin" @click.stop="menuLink('mypositions')">Volunteer Positions</li>
    <li v-if="userloggedin" @click.stop="logout()">Logout</li>
    <li v-else @click.stop="logout()">Logout</li>
  </ul>

</b-col>
</b-row>
  
</b-container>  

<b-row>
 <b-col class="col-12 col-xl-10 mt-3">
 
      <b-navbar type="light" variant="light" toggleable="md">
        <b-navbar-brand href="#"><img ref="brandingLogo" :src="logo" style="height:60px;padding:1px;"></b-navbar-brand>

      <b-navbar-toggle @click.stop=showMenu() target="nav_collapsez" style="z-index:999;margin-top:10px"></b-navbar-toggle>
    
      <b-collapse id="nav_collapse" is-nav>
        <b-nav class="nav-fill w-100">
          <b-nav-item @click.stop="menuLink('home')">Home</b-nav-item>
          <b-nav-item @click.stop="menuLink('directory')">Directory</b-nav-item>
          <b-nav-item @click.stop="menuLink('volunteerpositions')">Volunteering</b-nav-item>
          <b-nav-item @click.stop="menuLink('helpsupport')">Help & Support</b-nav-item>

          <b-nav-item-dropdown  v-if="userloggedin" class="navdropdown" text="Mycare4me">
            <b-dropdown-item @click.stop="menuLink('mycare4me')">Account</b-dropdown-item>
            <b-dropdown-item @click.stop="menuLink('mylistings')">Listings</b-dropdown-item>
            <b-dropdown-item @click.stop="menuLink('mypositions')">Volunteer Positions</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item v-if="!userloggedin" @click.stop="userLogin()">Login</b-nav-item>
          <b-nav-item v-else @click.stop="logout()">Logout</b-nav-item>
        </b-nav>
      </b-collapse>
      </b-navbar>

</b-col> 

<b-col class="col-12 col-md-2 col-xl-2 text-xl-right">
      <div id="google_translate_element" style="margin-top:10px;height:60px;width:auto"></div>
</b-col> 

 <b-col class="col-12 col-md-7 offset-md-1 col-xl-6 offset-xl-3 mb-3">
      <SearchBar :home="ishome" />
    </b-col>
  </b-row>
  
  <b-modal ref="user-profile" size="md" :title="modaltitle"  hide-footer centered>
    <user-profile />
  </b-modal>   
  
  <b-modal ref="user-login" size="md" :title="modaltitle"  hide-footer centered>
    <b-container>

      <form v-on:submit.prevent="login" >
       <b-row class="pt-1 pb-1 pl-5 pr-5">

        <b-col class="col-12">
          <label>E-mail Address</label>
          <b-form-input 
                type="search"
                placeholder=""
                autocomplete="off" 
                v-model="username" 
                name="username"
                v-validate="{required:true}"
                :class="{'darkinput': true, 'is-danger': errors.has('username') }"
                @change = "validateField('username')" 
                @input = "validateField('username')"  
                />
                <span class="invalid-feedback" role="alert" v-if="loginerror">
                    <strong>Login error</strong>
                </span>
       </b-col>
        <b-col class="col-12 mt-1">
          <label>Password</label>
          <b-form-input 
                type="search"
                placeholder=""
                autocomplete="off" 
                v-model="password" 
                name="password"
                v-validate="{required:true}"
                :class="{'darkinput': true, 'is-danger': errors.has('password') }"
                @change = "validateField('password')" 
                @input = "validateField('password')"  
                />
                <span class="invalid-feedback" role="alert" v-if="loginerror">
                    <strong>Login error</strong>
                </span>
          </b-col>
  
          <b-col class="col-12 col-sm-4 mt-3">
              <b-btn variant="outline-info" block @click.stop="login()" >Login</b-btn>
          </b-col>
          
          <b-col class="col-12 col-sm-8 mt-3" align="right" @click="forgotPassword()">
                <h6>Forgot password?</h6>
          </b-col>    
        
       </b-row>
  
      </form>

    </b-container>
      
  </b-modal>   
   
</div>
</template> 

<!-- <style scoped>
.order-lg-1,.order-md-1,.order-sm-1,.order-xs-1 {
  order: 1; /* Display this column first on sm and larger screens */
}

.order-lg-2,.order-md-2,.order-sm-2,.order-xs-2 {
  order: 2; /* Display this column second on sm and larger screens */
}

.order-lg-3,.order-md-3,.order-sm-3,.order-xs-3 {
  order: 3; /* Display this column third on sm and larger screens */
}
</style> -->
  
  <script>
  import logo from '@/assets/images/care4me_logo_l.jpeg'
  import logo2 from '@/assets/images/care4me_logo_r.jpeg'
  import SearchBar from './SearchBar.vue'
  import { mapState } from 'vuex'
  import axios from 'axios'
  
  export default { 
    components: { SearchBar },
    data(){
      return{
        logo,
        logo2,
        page:'',
        userloggedin:false,
        username:'',
        password:'',
        modaltitle:'',
        loginerror:false,
        mobile:false,
        ishome:false,
        isOpen:false,
        slidestyle:'slideout',
      }
    },
  
    beforeDestroy() {
            window.removeEventListener("resize",this.setMobile)
    }, 

    mounted(){
        this.setMobile()
        window.addEventListener("resize", this.setMobile)
        this.userloggedin = this.$store.getters.loggedin
         setTimeout(() =>  this.googleTranslateElementInit(), 1000)

         
     },
  
     computed: mapState(['loggedin']), 
  
     watch: {
             loggedin(){
                  this.userloggedin = this.loggedin
              },
          },    
        
    methods: {

    setMobile(){
      if(document.getElementById("maincont").clientWidth < 500){this.mobile = true} else this.mobile = false
      this.$store.commit('setmobile',this.mobile)
      console.log('W:'+this.mobile)
    },   
  
    googleTranslateElementInit() {
          window.google.translate.TranslateElement(
            { 
              pageLanguage: "en",
              layout: "google.translate.TranslateElement.InlineLayout"
            },
            "google_translate_element"
          );
        },
  
    userLogin(){
          this.modaltitle = "Login"
          this.$refs['user-login'].show()
    },    
  
    userProfile(){
          this.modaltitle = "User Profile"
          this.$refs['user-profile'].show()
    },
    
    showSearch(){
      this.$refs['search-box'].show()
    },

    showMenu(){
      this.isOpen = !this.isOpen
      console.log(this.isOpen)
      // if(!this.sidemenu){this.slidestyle = 'menuopen' } 
      // console.log(this.slidestyle)
    },
        
    login(){
              axios({
              method: 'post',
              url: '/api/auth/vuelogin',
              data:{
                  email: this.username,
                  password: this.password
              }
              })
              .then((response) => {
                 
                if(response.data.status == 'login error'){
                    this.loginerror = true
                  } else {
                       this.$refs['user-login'].hide()
                       this.$store.commit('setloggedin',true)
                       this.$store.commit('setuser',response.data.user)
                       this.$store.commit('settoken',response.data.token) 
                  }
              })
              .catch(response => {
              alert('System login error'+response)
              }) 
              
          },
  
          logout(){
              this.$store.commit('setloggedin',false)
              this.$store.commit('setuser','')
              this.$router.push({ name: 'home', params: {} }).catch(()=>{})
          },
  
          forgotPassword(){
            this.$router.push({ name: 'reset-password', params: {} }).catch(()=>{})
           },

          validateField(field){
                this.$validator.validate(field)
          },
  
          menuLink(link){
                this.isOpen = false
                this.$router.push({ name: link, params: {} }).catch(()=>{})
          },
  
      } 
  }
  </script>