<template>
<div >
  <b-container class="pagecont pb-5" v-if="display">

    <b-row class="textbox" id="secbox">
      <b-col class="col-10 col-sm-10 col-xl-6 offset-xl-2 mt-2">
            <h4>{{ listing.text }}</h4>
         </b-col>
         
         <b-col class="col-2 col-sm-2 col-xl-2 text-right">
          <b-btn variant="outline" @click="$router.go(-1)"><b-icon-x-circle style="width:1.5em;height:1.5em;color:white;" /></b-btn>
         </b-col> 
   </b-row>  
   
   
  <b-row class="mt-3">

    <b-col class="col-12 col-md-6 col-xl-4 offset-xl-2">
      <b-row>

                <b-col class="col-12 col-md-6 mb-5" v-if="listing.page_logo !== ''">
                    <img v-if="listing.logo_size[0] > listing.logo_size[1]" class="provider_logo_large" :src="`https://care4medev.co.uk/storage/providerimages/${listing.page_logo}` ">
                    <img v-else class="provider_logo" :src="`https://care4medev.co.uk/storage/providerimages/${listing.page_logo}` ">
                  </b-col>
     </b-row>
     
     <b-row>
                <b-col class="col-12">
                <!-- <h3>{{ listing.text }}</h3>   -->
                <h4>{{ listing.text2 }}</h4>
                <div v-html="listing.page_desc" style="margin-top:20px"/>
                </b-col>
      </b-row>

      <b-row class="mt-3"  v-if="listing.tel !== ''">
            <b-col class="col-1"><b-icon-telephone /></b-col>
            <b-col class="col-11">{{ listing.tel }}</b-col>
          </b-row>

          <b-row class="mt-3"  v-if="listing.email !== ''">
            <b-col class="col-1"><b-icon-envelope /></b-col>
            <b-col class="col-11">{{ listing.email }}</b-col>
          </b-row>

          <b-row class="mt-3"  v-if="listing.website !== ''">
            <b-col class="col-1"><b-icon-globe /></b-col>
            <b-col class="col-11"><a :href="listing.website" target="blank">{{ listing.website }}</a></b-col>
          </b-row>
        
       
        <b-row class="mt-3">

          <b-col class="col-1"><b-icon-geo-alt /></b-col>

            <b-col class="col-11">
                <span v-if="listing.add1 !== ''">{{ listing.add1 }}<br/></span>
                <span v-if="listing.add2 !== ''">{{ listing.add2 }}<br/></span>
                <span v-if="listing.add3 !== ''">{{ listing.add3 }}<br/></span>
                {{ listing.postcode }}<br/>
            </b-col>
        </b-row>
  </b-col>
  
  <b-col class="col-12 col-md-6 col-xl-4 offset-xl-0 mt-xl-5">
    
    <b-row class="mt-3" v-if="mapshow">
        <b-col class="col-12 m-0 p-0 border" >
          <GmapMap
              :center="center"
              :zoom="17"
              map-type-id="terrain"
              :style="getWidth()"
              :options="getOptions()"
              loading=async
              >   
              
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :label="m.label"
                :title="m.title"
                :clickable="true"
                :draggable="true"
                @click="center=m.position"
              />
            </GmapMap>
        </b-col>

        </b-row>
                   
</b-col>        
       
</b-row>

<b-row class="mt-3 mb-5 pb-5">
          <b-col class="col-12 col-xl-8 offset-xl-2 mt-3">
            <h4>Listing groups</h4>
            <div v-for="cat in pcats" :key="cat.id" class="parentcat" @click.stop="gotoPCat(cat)">
              {{ cat.parentcatname }}
            </div>
          </b-col>
          <b-col class="col-12 col-xl-8 offset-xl-2 mt-3">
            <h4>Listing types</h4>
            <div v-for="cat in ccats" :key="cat.id" class="childcat"  @click.stop="gotoCCat(cat)">
              {{ cat.catname }}
            </div>
          </b-col>
</b-row>  

</b-container>     

<SiteFooter v-if="display" />
</div> 
</template>

<style>
.marker-position{
    top: 40px !important;
    left: 10px !important;
    color:white !important;
    position: relative;
    font-size:1.2em !important;
    background-color: #21598c !important;
    padding:5px;
}

.border{
  border:1px solid black;
}

.parentcat{
  background-color:rgb(170, 213, 228, 0.7);float:left;padding:5px;border-radius:5px;margin-right:5px;margin-top:5px;
}

.parentcat:hover{
  cursor: pointer;
  background-color:rgb(170, 213, 228, 1);
  color:white;
}

.childcat{
  background-color:rgb(230, 200, 173, 0.7);float:left;padding:5px;border-radius:5px;margin-right:5px;margin-top:5px;
}

.childcat:hover{
  cursor: pointer;
  background-color:rgb(230, 200, 173, 1);
  color:white;
}

.cancelcat{
  background-color:rgb(233, 35, 58, 0.7);float:left;padding:5px;border-radius:5px;margin-right:5px;margin-top:5px;
}

.cancelcat:hover{
  cursor: pointer;
  background-color:rgb(233, 35, 58, 1);
  color:white;
}
</style>
<script>
  

  import axios from 'axios'
  import SiteFooter from './SiteFooter.vue'
  export default {
    components: {
      SiteFooter
    },
    data(){
        return {
                providerid:0,  
                listing:{
                  value:0,
                  text:'',
                  text2:'',
                  page_desc:'',
                  add1:'',
                  add2:'',
                  add3:'',
                  towncity:'',
                  county:'',
                  postcode:'',
                  email:'',
                  website:'',
                  tel:'',
                  mobile:'',
                  page_logo:'',
                  logosize:[0,0],
                  gm_lat:0,
                  gm_long:0
                },
                pcats:[],
                ccats:[],
                lat:0,
                long:0,
                center:{},
                display:false,  
                mapshow:false,
                markers:[
                  
                ]  
           };
        },

       
        mounted(){
            window.scrollTo(0, 0)
            this.providerid = this.$route.params.id
            this.fetchProvider()
        },

        watch: {
			
       
        },
       
        methods: {

           showMap(){
            let lat = parseFloat(this.listing.gm_lat)
            let long = parseFloat(this.listing.gm_long)
            if(lat == 0 || long == 0){
              this.mapshow = false
            } else {
            let label = {text:this.listing.text,className: 'marker-position'}
            let add = this.listing.add1+','+this.listing.postcode
            this.center = {lat:lat,lng:long}
            this.markers.push({position:{lat:lat,lng:long},title:add,label:label})
            this.mapshow = true
            }

           },

           getOptions() {
            return {
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false
            };
    },

            getWidth(){
              return{
                width: 100+'%',
                height: 400+'px'
              }
            },


           fetchProvider(){
                    
                axios.get('https://care4medev.co.uk/api/getprovider',{
                    params:{
                        id:this.providerid
                     }
                })
				.then((response) => {
                    this.listing = response.data.Providers[0]
                    this.pcats = response.data.PCats
                    this.ccats = response.data.CCats
                    this.display = true
                    if(this.listing.gm_lat){
                      this.showMap()
                    }
                    
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
  
               },
               
               
               gotoPCat(cat){
                 
                  this.$store.commit('setpcatsel',cat.parentid)
                  this.$store.commit('setpcattext',cat.parentcatname)
                  this.$store.commit('setcatsel',0)
                  this.$store.commit('setcattext','')
                  this.$store.commit('setstext','')
                  this.updateCatSel()
               },
               
               gotoCCat(cat){
                  this.$store.commit('setpcatsel',cat.parentid)
                  this.$store.commit('setpcattext',cat.parentcatname)
                  this.$store.commit('setcatsel',cat.catid)
                  this.$store.commit('setcattext',cat.catname)
                  this.$store.commit('setstext','')
                  this.updateCatSel()
               },
               
               updateCatSel(){
                 //  if(this.id !== this.$store.getters.catsel){
                  let update = this.$store.getters.updateresults
                  this.$store.commit('setupdateresults',update++)
                 // }
                  this.$router.push({ name: 'directory', params: {} }).catch(()=>{})
               }
           },
           
	
};

</script> 